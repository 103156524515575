import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Components.
import { Nav } from './Nav';
import { ProfileMenu } from './ProfileMenu';

// Hooks.
import { useTrackEvent } from 'hooks';

// Images.
import comptiaLogo from 'images/comptia-logo.svg';

// Styles.
import styles from './Header.module.scss';

export const Header = (): ReactElement => {
  const trackEvent = useTrackEvent('Header');

  const handleEvent = () => {
    trackEvent('Clicked', { label: 'CompTIA Logo' });
  };

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link onClick={handleEvent} to="/" className={styles.logo}>
          <img alt="CompTIA Logo" src={comptiaLogo} />
        </Link>
        <Nav />
        <ProfileMenu />
      </div>
    </header>
  );
};
