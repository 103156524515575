import {
  FormButton,
  FormTextField,
  ICertMasterPracticeProduct,
  IModalProps,
  Modal,
  ModalVariants,
  Target,
} from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks.
import { useTrackEvent } from 'hooks';

// Styles.
import styles from './ProductsModal.module.scss';
import { getValidationSchema } from './validationSchema';

export interface IProductsModalProps extends IModalProps {
  onSubmit: (values: { productId: number; productName: string }) => void;
  products?: ICertMasterPracticeProduct[];
}

export const ProductsModal = (props: IProductsModalProps): ReactElement => {
  const { onSubmit, products, ...modalProps } = props;
  const { t } = useTranslation();
  const trackEvent = useTrackEvent('Redeem Access Keys');
  const formik = useFormik({
    initialValues: {
      productId: undefined,
    },
    onSubmit: async (values, { resetForm }) => {
      trackEvent('Selected a Product', { value: Number(values.productId) });
      await onSubmit({
        productId: Number(values.productId),
        productName: String(
          products?.filter(
            (product) =>
              product.certMasterPracticeProductId === Number(values.productId),
          )[0].certMasterPracticeProductName,
        ),
      });
      resetForm();
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(t),
  });

  return (
    <Modal {...modalProps} variant={ModalVariants.Large}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <div className={styles.grid}>
            <div className={styles.iconContainer}>
              <Target width={56} height={56} />
            </div>
            <p>{t('RedeemModule.Products.Content')}</p>
            <FormTextField
              label={t('RedeemModule.Products.Label')}
              name="productId"
              select
            >
              {products?.map((product) => (
                <option
                  key={product.certMasterPracticeProductId}
                  value={product.certMasterPracticeProductId}
                >
                  {product.certMasterPracticeProductName}
                </option>
              ))}
            </FormTextField>
            <FormButton
              className={styles.button}
              disabled={formik.isSubmitting}
            >
              {t('RedeemModule.Products.Cta')}
            </FormButton>
          </div>
        </FormikProvider>
      </form>
    </Modal>
  );
};
