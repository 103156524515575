import {
  ICertMasterPracticeProduct,
  IError,
  IQueryHookOptions,
} from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { getCertMasterProducts } from 'services';

type GetCertMasterProductsFunction = UseMutateAsyncFunction<
  ICertMasterPracticeProduct[],
  IError,
  string
>;

type UseGetCertMasterProducts = [
  GetCertMasterProductsFunction,
  IQueryHookOptions,
];

export const useGetCertMasterProducts = (
  cmpId?: string,
): UseGetCertMasterProducts => {
  const { error, isError, isLoading, mutateAsync } = useMutation<
    ICertMasterPracticeProduct[],
    IError,
    string
  >((accessKey: string) => getCertMasterProducts(accessKey, cmpId));

  return [mutateAsync, { error, isError, isLoading }];
};
