/* istanbul ignore file */
import { ToastProvider } from '@comptia-sso/core';
import { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga4';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { env } from 'config';
import { AuthProvider } from 'providers';
import { appInsights } from 'utils';
import '@comptia-sso/core/dist/styles.css';
import { AppWrapper } from './app';
import reportWebVitals from './reportWebVitals';
import { i18n } from 'i18n';

appInsights.loadAppInsights();

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      staleTime: Infinity,
    },
  },
});

ReactGA.initialize(env.GA_ID, {
  testMode: process.env.NODE_ENV === 'development',
});

const Root = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <QueryClientProvider client={client}>
          <ToastProvider dismissText={i18n.t('Toast.Action.Dismiss')}>
            <AuthProvider>
              <AppWrapper />
            </AuthProvider>
          </ToastProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
};

render(
  <StrictMode>
    <Suspense fallback={null}>
      <Root />
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
