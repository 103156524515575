import { Vendors } from '@comptia-sso/core';
import { useCallback, useState } from 'react';

// Hooks.
import { PearsonActions } from 'hooks';
import { useRedirectToBenchPrep } from './useRedirectToBenchPrep';
import { useRedirectToCanvas } from './useRedirectToCanvas';
import { useRedirectToCertMasterPractice } from './useRedirectToCertMasterPractice';
import { useRedirectToCertMetrics } from './useRedirectToCertMetrics';
import { useRedirectToGutenberg } from './useRedirectToGutenberg';
import { useRedirectToSkillable } from './useRedirectToSkillable';
import { useRedirectToPearson } from './useRedirectToPearson';
import { useRedirectToPopuli } from './useRedirectToPopuli';
import { useRedirectToZendesk } from './useRedirectToZendesk';

interface VendorRedirectOptions {
  classroom?: string;
  pearsonAction?: PearsonActions;
  productCode?: string;
  returnUrl?: string;
}

type RedirectToVendorFunction = (
  vendor: Vendors,
  options?: VendorRedirectOptions,
) => void;

export const useRedirectToVendor = (
  profileId = '',
  target = '',
): [RedirectToVendorFunction, { isLoading: boolean }] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const redirectToBenchPrep = useRedirectToBenchPrep();
  const redirectToCanvas = useRedirectToCanvas();
  const redirectToCertMasterPractice = useRedirectToCertMasterPractice();
  const redirectToCertMetrics = useRedirectToCertMetrics();
  const redirectToGutenberg = useRedirectToGutenberg();
  const redirectToSkillable = useRedirectToSkillable(profileId, target);
  const redirectToPearson = useRedirectToPearson();
  const redirectToPopuli = useRedirectToPopuli();
  const redirectToZendesk = useRedirectToZendesk();

  const callback = useCallback(
    async (vendor: Vendors, options?: VendorRedirectOptions) => {
      setIsLoading(true);
      try {
        const { classroom, pearsonAction, productCode, returnUrl } =
          options || {};

        switch (vendor?.toLowerCase()) {
          case Vendors.BenchPrep.toLowerCase(): {
            await redirectToBenchPrep(productCode, target);
            break;
          }
          case Vendors.Canvas.toLowerCase(): {
            await redirectToCanvas(productCode, target);
            break;
          }
          case Vendors.CertMasterPractice.toLowerCase(): {
            await redirectToCertMasterPractice(classroom, target);
            break;
          }
          case Vendors.CertMetrics.toLowerCase(): {
            await redirectToCertMetrics(returnUrl);
            break;
          }
          case Vendors.Skillable.toLowerCase(): {
            await redirectToSkillable();
            break;
          }
          case Vendors.PearsonVue.toLowerCase(): {
            await redirectToPearson(pearsonAction);
            break;
          }
          case Vendors.Populi.toLowerCase(): {
            await redirectToPopuli(target);
            break;
          }
          case Vendors.Gutenberg.toLowerCase(): {
            await redirectToGutenberg(target);
            break;
          }
          case Vendors.Zendesk.toLowerCase(): {
            await redirectToZendesk();
            break;
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error; // To be handled upstream.
      }
    },
    [
      redirectToBenchPrep,
      redirectToCertMasterPractice,
      redirectToCertMetrics,
      redirectToGutenberg,
      redirectToPearson,
      redirectToPopuli,
      redirectToSkillable,
    ],
  );

  return [callback, { isLoading }];
};
