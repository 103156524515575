// import classNames from 'classnames';
import {
  Button,
  IError,
  List,
  ListItem,
  LoadingOverlay,
  Vendors,
  useToast,
} from '@comptia-sso/core';
import { MouseEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// Config.
import { env } from 'config';

// Hooks.
import {
  useProfile,
  useRedirectToVendor,
  useTrackEvent,
  useTrackOdpEvent,
} from 'hooks';

// Styles.
import styles from 'views/HomeView/HomeView.module.scss';

export const PearsonVueDashboard = (): ReactElement => {
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [profile] = useProfile();
  const [redirectToVendor, { isLoading: isRedirecting }] = useRedirectToVendor(
    profile?.id,
  );
  const trackEvent = useTrackEvent('Manage Your Exams');
  const trackOdpEvent = useTrackOdpEvent();

  const handleEvent = (e: MouseEvent<HTMLElement>) => {
    trackOdpEvent('navigation', 'Launchpad Button', 'Manage Exam');
    const element = e.target as HTMLElement;
    trackEvent('Clicked', { label: element.innerText });
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.content}>
          <h2>{t('PearsonVueDashboard.Title')}</h2>
          <p>{t('PearsonVueDashboard.Content')}</p>
          <List>
            <ListItem>{t('PearsonVueDashboard.List-1')}</ListItem>
            <ListItem>{t('PearsonVueDashboard.List-2')}</ListItem>
            <ListItem>{t('PearsonVueDashboard.List-3')}</ListItem>
          </List>
          {env.FLAG_PEARSON_ENABLED &&
          profile?.externalIds?.pearsonVue &&
          profile?.isBlocked === false ? (
            <Button
              className={styles.button}
              onClick={async (e) => {
                handleEvent(e);

                try {
                  await redirectToVendor(Vendors.PearsonVue);
                } catch (error) {
                  popToast(
                    (error as IError)?.message || t('Toast.Error.Default'),
                  );
                }
              }}
            >
              {t('PearsonVueDashboard.Button')}
            </Button>
          ) : (
            <Button
              className={styles.button}
              onClick={(e) => {
                handleEvent(e);
              }}
              link
              to="https://www.comptia.org/testing/testing-options/about-testing-options"
            >
              {t('PearsonVueDashboard.Button-2')}
            </Button>
          )}
        </div>
      </div>
      <LoadingOverlay isOpen={isRedirecting} text={t('Loading.Default')} />
    </>
  );
};
