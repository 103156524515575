import { IQueryHookOptions, IEntitlement, IError } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Config.
import { env } from 'config';

// Services.
import { getProfileEntitlements } from 'services';

type UseProfileEntitlements = [IEntitlement[], IQueryHookOptions];

export const useProfileEntitlements = (id = ''): UseProfileEntitlements => {
  const {
    data = [],
    error,
    isError,
    isLoading,
  } = useQuery<IEntitlement[], IError>(
    ['profile', 'entitlements'],
    () => getProfileEntitlements(id),
    {
      enabled: env.FLAG_LEARNING_SYSTEMS_ENABLED && !!id,
    },
  );

  return [data, { error, isError, isLoading }];
};
