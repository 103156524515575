import {
  IAddressIndexed,
  IPhoneIndexed,
  countries,
  states,
  getPrimaryAddressFromProfile,
  getPrimaryPhoneNumberFromProfile,
} from '@comptia-sso/core';
import { ReactElement, useEffect, useMemo } from 'react';
import { useProfile } from 'hooks';
import { env } from 'config';

export const OptimizelyDataPlatform = (): ReactElement => {
  const [profile] = useProfile();

  const primaryAddress: IAddressIndexed = useMemo(
    () => getPrimaryAddressFromProfile(profile) ?? ({} as IAddressIndexed),
    [profile],
  );
  const primaryPhoneNumber: IPhoneIndexed = useMemo(
    () => getPrimaryPhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );

  useEffect(() => {
    if (!document.getElementById('odp-settings')) {
      const setupScript = document.createElement('script');
      setupScript.type = 'text/javascript';
      setupScript.id = 'odp-settings';
      setupScript.async = true;
      setupScript.innerHTML = `
        var zaius = window['zaius']||(window['zaius']=[]);zaius.methods=["initialize","onload","customer","entity","event","subscribe","unsubscribe","consent","identify","anonymize","dispatch"];zaius.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);zaius.push(t);return zaius}};(function(){for(var i=0;i<zaius.methods.length;i++){var method=zaius.methods[i];zaius[method]=zaius.factory(method)}var e=document.createElement("script");e.type="text/javascript";e.async=true;e.src=("https:"===document.location.protocol?"https://":"http://")+"d1igp3oop3iho5.cloudfront.net/v2/${env.ODP_KEY}/zaius-min.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)})();

        // Edits to this script should only be made below this line.
        zaius.event('pageview');
      `;

      document.head.appendChild(setupScript);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (profile) {
        let state = '';
        let country = '';

        if (
          primaryAddress.state !== undefined &&
          primaryAddress.state !== null &&
          primaryAddress.state !== ''
        ) {
          const stateFilter = states.filter(
            (state) => state.abbreviation === primaryAddress.state,
          );
          if (stateFilter.length) {
            state = stateFilter[0].name;
          }
        }

        if (
          primaryAddress.country !== undefined &&
          primaryAddress.country !== null &&
          primaryAddress.country !== ''
        ) {
          const countryFilter = countries.filter(
            (country) => country.abbreviation === primaryAddress.country,
          );
          if (countryFilter.length) {
            country = countryFilter[0].name;
          }
        }

        const setupScript = document.createElement('script');
        setupScript.type = 'text/javascript';
        setupScript.id = 'odp-customer-settings';
        setupScript.async = true;
        setupScript.innerHTML = `
          zaius.customer({email: "${profile.email}"},{
            first_name: "${
              profile.givenName.firstName !== undefined &&
              profile.givenName.firstName !== null
                ? profile.givenName.firstName.replace(/["]+/g, '')
                : ''
            }",
            last_name: "${
              profile.givenName.lastName !== undefined &&
              profile.givenName.lastName !== null
                ? profile.givenName.lastName.replace(/["]+/g, '')
                : ''
            }",
            street1: "${
              primaryAddress.address1 !== undefined &&
              primaryAddress.address1 !== null
                ? primaryAddress.address1.replace(/["]+/g, '\\"')
                : ''
            }",
            street2: "${
              primaryAddress.address2 !== undefined &&
              primaryAddress.address2 !== null
                ? primaryAddress.address2.replace(/["]+/g, '')
                : ''
            }",
            city: "${
              primaryAddress.city !== undefined && primaryAddress.city !== null
                ? primaryAddress.city.replace(/["]+/g, '')
                : ''
            }",
            state: "${state}",
            country: "${country}",
            zip: "${
              primaryAddress.postalCode !== undefined &&
              primaryAddress.postalCode !== null
                ? primaryAddress.postalCode.replace(/["]+/g, '')
                : ''
            }",
            dob_month: "${
              profile.birthMonth !== undefined && profile.birthMonth !== null
                ? profile.birthMonth
                : ''
            }",
            dob_year: "${
              profile.birthYear !== undefined && profile.birthYear !== null
                ? profile.birthYear
                : ''
            }",
            phone: "${
              primaryPhoneNumber.phone !== undefined &&
              primaryPhoneNumber.phone !== null
                ? primaryPhoneNumber.phone
                : ''
            }",
            company: "${
              profile.company !== undefined && profile.company !== null
                ? profile.company.replace(/["]+/g, '')
                : ''
            }",
          });
        `;

        document.head.appendChild(setupScript);
      }
    })();
  }, [profile]);

  return <div />;
};

export default OptimizelyDataPlatform;
