import { MouseEvent, ReactElement } from 'react';
import { SocialIcon } from 'react-social-icons';
import { useTranslation } from 'react-i18next';

// Hooks.
import { useTrackEvent } from 'hooks';

// Images.
import comptiaFooterLogo from 'images/logo-footer.svg';

// Styles.
import styles from './Footer.module.scss';

export const Footer = (): ReactElement => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent('Footer');

  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const link = e.target as HTMLAnchorElement;
    trackEvent('Clicked', { label: link.innerText });
  };

  const handleSocialClick = (network: string) => () => {
    trackEvent('Clicked', { label: network });
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.column}>
          <img
            alt="CompTIA Logo"
            src={comptiaFooterLogo}
            className={styles.footerLogo}
          />
          <div className={styles.mobileShift}>
            <address>
              3500 Lacey Road, Suite 100
              <br />
              Downers Grove, IL 60515
            </address>
            <div className={styles.socialIcons}>
              <SocialIcon
                onClick={handleSocialClick('Facebook')}
                network="facebook"
                url="https://www.facebook.com/CompTIA/"
                bgColor="transparent"
                fgColor="#fff"
                style={{ height: 35, width: 35 }}
                target="_blank"
              />
              <SocialIcon
                onClick={handleSocialClick('Twitter')}
                network="twitter"
                url="https://twitter.com/comptia"
                bgColor="transparent"
                fgColor="#fff"
                style={{ height: 35, width: 35 }}
                target="_blank"
              />
              <SocialIcon
                onClick={handleSocialClick('YouTube')}
                network="youtube"
                url="https://www.youtube.com/comptia"
                bgColor="transparent"
                fgColor="#fff"
                style={{ height: 35, width: 35 }}
                target="_blank"
              />
              <SocialIcon
                onClick={handleSocialClick('LinkedIn')}
                network="linkedin"
                url="https://www.linkedin.com/company/comptia"
                bgColor="transparent"
                fgColor="#fff"
                style={{ height: 35, width: 35 }}
                target="_blank"
              />
              <SocialIcon
                onClick={handleSocialClick('Instagram')}
                network="instagram"
                url="https://www.instagram.com/comptiaofficial/"
                bgColor="transparent"
                fgColor="#fff"
                style={{ height: 35, width: 35 }}
                target="_blank"
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>{t('FooterColumn-1.Title')}</div>
          <ul className={styles.navList}>
            <li>
              <a
                onClick={handleLinkClick}
                href={t('FooterColumn-1-Links.Link-1')}
                rel="noreferrer"
                target="_blank"
              >
                {t('FooterColumn-1.Link-1')}
              </a>
            </li>
            <li>
              <a
                onClick={handleLinkClick}
                href={t('FooterColumn-1-Links.Link-2')}
                rel="noreferrer"
                target="_blank"
              >
                {t('FooterColumn-1.Link-2')}
              </a>
            </li>
            <li>
              <a
                onClick={handleLinkClick}
                href={t('FooterColumn-1-Links.Link-3')}
                rel="noreferrer"
                target="_blank"
              >
                {t('FooterColumn-1.Link-3')}
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>{t('FooterColumn-2.Title')}</div>
          <ul className={styles.navList}>
            <li>
              <a
                onClick={handleLinkClick}
                href="https://www.comptia.org/certifications"
                rel="noreferrer"
                target="_blank"
              >
                {t('FooterColumn-2.Link-1')}
              </a>
            </li>
            <li>
              <a
                onClick={handleLinkClick}
                href="https://www.comptia.org/training"
                rel="noreferrer"
                target="_blank"
              >
                {t('FooterColumn-2.Link-2')}
              </a>
            </li>
            <li>
              <a
                onClick={handleLinkClick}
                href="https://www.comptia.org/continuing-education"
                rel="noreferrer"
                target="_blank"
              >
                {t('FooterColumn-2.Link-3')}
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>{t('FooterColumn-3.Title')}</div>
          <ul className={styles.navList}>
            <li>
              <a
                onClick={handleLinkClick}
                href="https://store.comptia.org/"
                rel="noreferrer"
                target="_blank"
              >
                {t('FooterColumn-3.Link-1')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
