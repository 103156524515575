import { useCallback } from 'react';
import { env } from 'config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const zaius: any;

type TrackODPEventFunction = (
  eventType: string,
  action: string,
  eventLabel: string,
) => void;

export const useTrackOdpEvent = (): TrackODPEventFunction => {
  return useCallback(
    (eventType: string, action: string, eventLabel: string) => {
      if (env.FLAG_ODP_ENABLED) {
        if (process.env.NODE_ENV === 'development') {
          console.log(
            `ODP Event - Type: ${eventType}, Action: ${action}, Label: ${eventLabel}`,
          );
        }
        if (eventLabel !== '') {
          zaius.event(eventType, {
            action: action,
            event_label: eventLabel,
          });
        } else {
          zaius.event(eventType, {
            action: action,
          });
        }
      }
    },
    [],
  );
};
