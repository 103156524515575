import { LoadingOverlay, useQueryParams } from '@comptia-sso/core';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks.
import { useAuthLogout, useTrackOdpEvent } from 'hooks';
import { env } from 'config';
import { OptimizelyDataPlatform } from 'components';

export const LogoutView = (): ReactElement => {
  const { t } = useTranslation();
  const logout = useAuthLogout();
  const { redirectUrl = '/' } = useQueryParams();
  const trackOdpEvent = useTrackOdpEvent();

  useEffect(() => {
    trackOdpEvent('account', 'logout', '');
    logout(redirectUrl);
  }, [logout]);

  return (
    <>
      {env.FLAG_ODP_ENABLED && <OptimizelyDataPlatform />}
      <LoadingOverlay isOpen text={t('Loading.Default')} />
    </>
  );
};
