import { useCallback } from 'react';
import ReactGA from 'react-ga4';

type TrackEventOptions = { label?: string; value?: number };
type TrackEventFunction = (action: string, options?: TrackEventOptions) => void;

export const useTrackEvent = (category: string): TrackEventFunction => {
  return useCallback(
    (action: string, options?: TrackEventOptions) => {
      const { label, value } = options || {};
      ReactGA.event({
        action: action,
        category: category,
        label: label,
        value: value,
      });
    },
    [category],
  );
};
