import { IAccessKeyVendor, IError, IQueryHookOptions } from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { getAccesskeyVendor } from 'services';

type GetAccessKeyVendorFunction = UseMutateAsyncFunction<
  IAccessKeyVendor,
  IError,
  string
>;

type UseAccessKeyVendor = [GetAccessKeyVendorFunction, IQueryHookOptions];

export const useGetAccessKeyVendor = (): UseAccessKeyVendor => {
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IAccessKeyVendor,
    IError,
    string
  >((accessKey: string) => getAccesskeyVendor(accessKey));

  return [mutateAsync, { error, isError, isLoading }];
};
