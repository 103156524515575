import {
  Button,
  ButtonVariants,
  CheckCircle,
  IAddressIndexed,
  IError,
  IModalProps,
  LoadingOverlay,
  Modal,
  ModalVariants,
  Vendors,
  getPrimaryAddressFromProfile,
  useToast,
} from '@comptia-sso/core';
import { MouseEvent, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks.
import { useProfile, useRedirectToVendor, useTrackEvent } from 'hooks';

// Styles.
import styles from './SuccessModal.module.scss';

interface ISuccessModalProps extends IModalProps {
  vendor?: Vendors;
  productCode?: string;
  productTitle?: string;
}

export const SuccessModal = (props: ISuccessModalProps): ReactElement => {
  const { onClose, vendor, productCode, productTitle } = props;
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [profile] = useProfile();
  const trackEvent = useTrackEvent('Redeem Access Keys');
  const [redirectToVendor, { isLoading: isRedirecting }] = useRedirectToVendor(
    profile?.id,
  );

  const handleEvent = (e: MouseEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    trackEvent('Clicked', { label: element.innerText });
  };

  const primaryAddress: IAddressIndexed = useMemo(
    () => getPrimaryAddressFromProfile(profile) ?? ({} as IAddressIndexed),
    [profile],
  );

  return (
    <>
      <Modal {...props} variant={ModalVariants.Small}>
        <div className={styles.grid}>
          <div className={styles.iconContainer}>
            <CheckCircle width={56} height={56} />
          </div>
          {vendor !== Vendors.Populi ? (
            <p>{t('RedeemModule.Success.Content')}</p>
          ) : productTitle?.includes(t('RedeemModule.Success.LOT')) &&
            primaryAddress.country === 'USA' ? (
            <p>{t('RedeemModule.Success.ContentPopuliWaitlist')}</p>
          ) : (
            <p>{t('RedeemModule.Success.ContentPopuliAutoEnrolled')}</p>
          )}
          {vendor !== Vendors.Populi && (
            <Button
              className={styles.button}
              onClick={async (e) => {
                handleEvent(e);

                try {
                  await redirectToVendor(
                    vendor as Vendors,
                    productCode ? { productCode: productCode } : {},
                  );
                } catch (error) {
                  popToast(
                    (error as IError)?.message || t('Toast.Error.Default'),
                  );
                }
              }}
            >
              {t('RedeemModule.Success.Cta')}
            </Button>
          )}
          <Button
            link
            onClick={(e) => {
              handleEvent(e);
              onClose?.();
            }}
            to="/training-products"
            variant={ButtonVariants.Secondary}
          >
            {t('RedeemModule.Success.ViewAll')}
          </Button>
        </div>
      </Modal>
      <LoadingOverlay isOpen={isRedirecting} text={t('Loading.Default')} />
    </>
  );
};
