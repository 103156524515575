import {
  ICertMasterPracticeProduct,
  ICertMasterPracticeEntitlementRedemptionRequest,
  IEntitlement,
  IEntitlementRedemptionRequest,
  IAccessKeyVendor,
} from '@comptia-sso/core';

// Config.
import { env } from 'config';

// Services.
import { getRequest, postRequest } from './api';

export const getCertMasterProducts = (
  accessKey: string,
  cmpId?: string,
): Promise<ICertMasterPracticeProduct[]> =>
  getRequest<ICertMasterPracticeProduct[]>(
    [
      `${env.ENTITLEMENTS_API_HOST}/api/vendors/cmp/${accessKey}`,
      cmpId && `?cmpId=${cmpId}`,
    ]
      .filter(Boolean)
      .join(''),
    env.ENTITLEMENTS_API_KEY,
  );

export const redeemAccessKey = (
  values: IEntitlementRedemptionRequest,
): Promise<IEntitlement> =>
  postRequest<IEntitlement, IEntitlementRedemptionRequest>(
    `${env.ENTITLEMENTS_API_HOST}/api/redemptions/redeem`,
    values,
    env.ENTITLEMENTS_API_KEY,
  );

export const redeemCertMasterPracticeAccessKey = (
  values: ICertMasterPracticeEntitlementRedemptionRequest,
): Promise<boolean> =>
  postRequest<boolean, ICertMasterPracticeEntitlementRedemptionRequest>(
    `${env.ENTITLEMENTS_API_HOST}/api/redemptions/cmp/redeem`,
    values,
    env.ENTITLEMENTS_API_KEY,
  );

export const getAccesskeyVendor = (
  accessKey: string,
): Promise<IAccessKeyVendor> =>
  getRequest(
    `${env.ENTITLEMENTS_API_HOST}/api/vendors/getvendor/${accessKey}`,
    env.ENTITLEMENTS_API_KEY,
  );
