import {
  IError,
  IQueryHookOptions,
  IRedeemCertMasterPracticeAccessKeyRequest,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { redeemCertMasterPracticeAccessKey } from 'services';

type RedeemCertMasterPracticeAccessKeyFunction = UseMutateAsyncFunction<
  boolean,
  unknown,
  IRedeemCertMasterPracticeAccessKeyRequest
>;

type UseRedeemAccessKey = [
  RedeemCertMasterPracticeAccessKeyFunction,
  IQueryHookOptions,
];

export const useRedeemCertMasterPracticeAccessKey = (
  id = '',
): UseRedeemAccessKey => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    boolean,
    IError,
    IRedeemCertMasterPracticeAccessKeyRequest
  >(
    (values: IRedeemCertMasterPracticeAccessKeyRequest) =>
      redeemCertMasterPracticeAccessKey({
        ...values,
        profileId: id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profile', 'entitlements']);
      },
    },
  );

  return [mutateAsync, { error, isError, isLoading }];
};
