import {
  Button,
  ButtonTypes,
  FormTextField,
  IAddress,
  IModalProps,
  IProfile,
  Modal,
  countries,
  getPrimaryAddressFromProfile,
  getStatesByCountry,
} from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Styles.
import styles from './AddressVerificationModal.module.scss';

// Validations.
import { getValidationSchema } from './validationSchema';

interface IAddressVerificationModalProps extends IModalProps {
  profile?: IProfile;
  onSubmit: (values: Partial<IAddress>) => void;
}

export const AddressVerificationModal = (
  props: IAddressVerificationModalProps,
): ReactElement => {
  const { onSubmit, profile } = props;
  const { t } = useTranslation();
  const primaryAddress = useMemo(
    () => getPrimaryAddressFromProfile(profile),
    [profile],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address1: primaryAddress?.address1 || '',
      address2: primaryAddress?.address2 || '',
      address3: primaryAddress?.address3 || '',
      city: primaryAddress?.city || '',
      country: primaryAddress?.country || 'USA',
      isPrimary: primaryAddress?.isPrimary ?? true,
      postalCode: primaryAddress?.postalCode || '',
      state: primaryAddress?.state || '',
    },
    onSubmit,
    validateOnChange: false,
    validationSchema: getValidationSchema(t),
  });

  const states = useMemo(
    () => getStatesByCountry(formik?.values.country || 'USA'),
    [formik?.values.country],
  );

  return (
    <Modal {...props} title={t('AddressVerificationModal.Title')}>
      <FormikProvider value={formik}>
        <form className={styles.form} noValidate onSubmit={formik.handleSubmit}>
          <div className={styles.grid}>
            <FormTextField
              label={t('Profile.Address.Country')}
              name="country"
              required
              select
            >
              {countries?.map((country) => (
                <option key={country.abbreviation} value={country.abbreviation}>
                  {country.name}
                </option>
              ))}
            </FormTextField>
            <FormTextField
              label={t('Profile.Address.Address1')}
              maxLength={40}
              name="address1"
              required
            />
            <FormTextField
              label={t('Profile.Address.Address2')}
              maxLength={40}
              name="address2"
            />
            <FormTextField
              label={t('Profile.Address.Address3')}
              maxLength={40}
              name="address3"
            />
            <FormTextField
              label={t('Profile.Address.City')}
              maxLength={32}
              name="city"
              required
            />
            {states?.length > 0 && (
              <FormTextField
                label={t('Profile.Address.State')}
                name="state"
                required={['USA', 'CAN'].includes(
                  formik.values.country || 'USA',
                )}
                select
              >
                {states?.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </FormTextField>
            )}
            <FormTextField
              label={t('Profile.Address.PostalCode')}
              maxLength={16}
              name="postalCode"
              required={['USA', 'CAN'].includes(formik.values.country || 'USA')}
            />
            <Button type={ButtonTypes.Submit}>
              {t('AddressVerificationModal.Button')}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};
