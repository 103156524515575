import {
  FindInPage,
  FormButton,
  FormCheckbox,
  FormPhoneFields,
  FormTextField,
  IAddressIndexed,
  IError,
  IModalProps,
  IPhoneIndexed,
  IProfile,
  IUpdateProfileRequest,
  Modal,
  ModalVariants,
  TextFieldTypes,
  countries,
  getPrimaryAddressFromProfile,
  getPrimaryPhoneNumberFromProfile,
  getMobilePhoneNumberFromProfile,
  getStatesByCountry,
  months,
  useToast,
  phoneTypes,
} from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks.
import { useTrackEvent } from 'hooks';

// Styles.
import styles from './DemograpghicsModal.module.scss';

// Validations.
import { getValidationSchema } from './validationSchema';

export interface IDemograpghicsModalProps extends IModalProps {
  onSubmit: (values: IUpdateProfileRequest) => void;
  profile?: IProfile;
}

export const DemograpghicsModal = (
  props: IDemograpghicsModalProps,
): ReactElement => {
  const { onSubmit, profile, ...modalProps } = props;
  const trackEvent = useTrackEvent('Redeem Access Keys');
  const [popToast] = useToast();
  const { t } = useTranslation();

  const primaryAddress: IAddressIndexed = useMemo(
    () => getPrimaryAddressFromProfile(profile) ?? ({} as IAddressIndexed),
    [profile],
  );
  const primaryPhoneNumber: IPhoneIndexed = useMemo(
    () => getPrimaryPhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );
  const mobilePhoneNumber: IPhoneIndexed = useMemo(
    () => getMobilePhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      allowConsentToGatherBirthInformation:
        profile?.allowConsentToGatherBirthInformation ?? false,
      allowConsentToSendSmsTextMessages:
        profile?.allowConsentToSendSmsTextMessages ?? false,
      birthDay: profile?.birthDay,
      birthMonth: profile?.birthMonth,
      birthYear: profile?.birthYear,
      isOlderThanEighteenYears: profile?.isOlderThanEighteenYears ?? false,
      mobilePhoneNumber: {
        countryCode: mobilePhoneNumber?.countryCode ?? '1',
        index: mobilePhoneNumber?.index,
        isPrimary: false,
        phone: mobilePhoneNumber?.phone || '',
        phonetype: mobilePhoneNumber.phoneType || phoneTypes.Cellphone,
      },
      primaryAddress: {
        address1: primaryAddress?.address1 || '',
        address2: primaryAddress?.address2 || '',
        address3: primaryAddress?.address3 || '',
        city: primaryAddress?.city || '',
        country: primaryAddress?.country || 'USA',
        index: primaryAddress?.index,
        isPrimary: true,
        postalCode: primaryAddress?.postalCode || '',
        state: primaryAddress?.state || '',
      },
      primaryPhoneNumber: {
        countryCode: primaryPhoneNumber?.countryCode ?? '1',
        index: primaryPhoneNumber?.index,
        isPrimary: true,
        phone: primaryPhoneNumber?.phone || '',
        phonetype: primaryPhoneNumber.phoneType || phoneTypes.Home,
      },
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        trackEvent('Entered Additional Demograpghics');
        await onSubmit(values);
        resetForm();
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(t),
  });

  const states = useMemo(
    () => getStatesByCountry(formik.values.primaryAddress.country),
    [formik.values.primaryAddress.country],
  );

  return (
    <Modal {...modalProps} variant={ModalVariants.Large}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <div className={styles.grid}>
            <div className={styles.iconContainer}>
              <FindInPage width={56} height={56} />
            </div>
            <p>{t('RedeemModule.Demograpghics.Content')}</p>
            <FormTextField
              label={t('Profile.Address.Country')}
              name="primaryAddress.country"
              required
              select
            >
              {countries?.map((country) => (
                <option key={country.abbreviation} value={country.abbreviation}>
                  {country.name}
                </option>
              ))}
            </FormTextField>
            <FormTextField
              label={t('Profile.Address.Address1')}
              maxLength={40}
              name="primaryAddress.address1"
              className={styles.textField}
              required
            />
            <FormTextField
              label={t('Profile.Address.Address2')}
              maxLength={40}
              className={styles.textField}
              name="primaryAddress.address2"
            />
            <FormTextField
              label={t('Profile.Address.Address3')}
              maxLength={40}
              className={styles.textField}
              name="primaryAddress.address3"
            />
            <FormTextField
              label={t('Profile.Address.City')}
              maxLength={32}
              className={styles.textField}
              name="primaryAddress.city"
              required
            />
            {states.length > 0 ? (
              <>
                <FormTextField
                  label={t('Profile.Address.State')}
                  name="primaryAddress.state"
                  className={styles.textField}
                  required={['USA', 'CAN'].includes(
                    formik.values.primaryAddress.country,
                  )}
                  select
                >
                  {states.map((state) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </FormTextField>
                <FormTextField
                  className={styles.zip}
                  label={t('Profile.Address.PostalCode')}
                  maxLength={16}
                  name="primaryAddress.postalCode"
                  required={['USA', 'CAN'].includes(
                    formik.values.primaryAddress.country,
                  )}
                />
              </>
            ) : (
              <FormTextField
                label={t('Profile.Address.PostalCode')}
                maxLength={16}
                name="primaryAddress.postalCode"
                required={['USA', 'CAN'].includes(
                  formik.values.primaryAddress.country,
                )}
              />
            )}
            <FormPhoneFields
              countryCodeClassName={styles.code}
              countryCodeName="primaryPhoneNumber.countryCode"
              countryCodeLabel={t('Profile.PhoneNumber.CountryCode')}
              phoneClassName={styles.phone}
              phoneName="primaryPhoneNumber.phone"
              phoneLabel={t('Profile.PhoneNumber.Phone')}
              required
              phoneType="primaryPhoneNumber.phonetype"
            />
            <FormPhoneFields
              countryCodeClassName={styles.code}
              countryCodeName="mobilePhoneNumber.countryCode"
              countryCodeLabel={t('Profile.MobilePhoneNumber.CountryCode')}
              phoneClassName={styles.phone}
              phoneName="mobilePhoneNumber.phone"
              phoneLabel={t('Profile.MobilePhoneNumber.Phone')}
              phoneType="mobilePhoneNumber.phonetype"
              required
            />
            <FormCheckbox
              label={t('Profile.AllowConsentToSendSmsTextMessages')}
              name="allowConsentToSendSmsTextMessages"
            />
            <FormTextField
              className={styles.birthDate}
              label={t('Profile.BirthMonth')}
              name="birthMonth"
              required
              select
            >
              {months.map((month, index) => (
                <option key={index} value={String(index + 1).padStart(2, '0')}>
                  {month}
                </option>
              ))}
            </FormTextField>
            <FormTextField
              className={styles.birthDate}
              label={t('Profile.BirthDay')}
              name="birthDay"
              required
              maxLength={2}
              type={TextFieldTypes.Number}
            />
            <FormTextField
              className={styles.birthDate}
              label={t('Profile.BirthYear')}
              maxLength={4}
              name="birthYear"
              required
              type={TextFieldTypes.Number}
            />
            <FormCheckbox
              label={t('Profile.IsOlderThanEighteenYears')}
              name="isOlderThanEighteenYears"
            />
            <FormCheckbox
              label={t('Profile.AllowConsentToGatherBirthInformation2')}
              name="allowConsentToGatherBirthInformation"
            />
            <FormButton className={styles.button}>
              {t('RedeemModule.Demograpghics.Cta')}
            </FormButton>
          </div>
        </FormikProvider>
      </form>
    </Modal>
  );
};
