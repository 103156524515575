import { useCallback } from 'react';

// Config.
import { env } from 'config';

export const useRedirectToCanvas = (): ((
  productCode?: string,
  target?: string,
) => void) => {
  return useCallback((productCode?: string, target?: string) => {
    window.open(
      [
        `${env.CANVAS_SSO_HOST}`,
        [productCode && `return_to=/app/${encodeURIComponent(productCode)}`]
          .filter(Boolean)
          .join('&'),
      ]
        .filter(Boolean)
        .join('?'),
      target || '_blank',
    );
  }, []);
};
