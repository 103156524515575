import {
  Button,
  IAddress,
  IError,
  LoadingOverlay,
  useToast,
} from '@comptia-sso/core';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components.
import { AddressVerificationModal } from '../AddressVerificationModal';

// Hooks.
import {
  useHasPendingFulfillments,
  useProfile,
  useReleaseFulfillments,
} from 'hooks';

// Styles.
import styles from './FulfillmentBanner.module.scss';

export const FulfillmentBanner = (): ReactElement => {
  const { t } = useTranslation();
  const [profile] = useProfile();
  const [hasPendingFulfillments] = useHasPendingFulfillments(profile?.id);
  const [releaseFulfillments, { isLoading: isReleasing }] =
    useReleaseFulfillments(profile?.id);
  const [popToast] = useToast();
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setIsVerifying(false);
  }, []);

  const handleSubmit = useCallback(async (values: Partial<IAddress>) => {
    try {
      await releaseFulfillments(values);
      popToast(t('AddressVerificationModal.SuccessMessage'));
      handleClose();
    } catch (error) {
      popToast((error as IError)?.message || t('Toast.Error.Default'));
    }
  }, []);

  return (
    <>
      {hasPendingFulfillments && (
        <div className={styles.banner}>
          <div className={styles.container}>
            <strong>{t('FullfillmentBanner.Title-1')}</strong>
            <Button
              className={styles.button}
              inverted
              onClick={() => setIsVerifying(true)}
            >
              {t('FullfillmentBanner.Button')}
            </Button>
          </div>
        </div>
      )}
      <AddressVerificationModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={isVerifying}
        profile={profile}
      />
      <LoadingOverlay
        isOpen={isReleasing}
        text={t('Loading.AddressVerification')}
      />
    </>
  );
};
