import { Button, IProfile, List, ListItem } from '@comptia-sso/core';
import { MouseEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// Config.
import { env } from 'config';

// Hooks.
import { useTrackEvent, useTrackOdpEvent } from 'hooks';

// Styles.
import styles from './ExamRegistration.module.scss';

interface IExamRegistrationProps {
  profile: IProfile;
}

export const ExamRegistration = (
  props: IExamRegistrationProps,
): ReactElement => {
  const { profile } = props;
  const { t } = useTranslation();
  const trackEvent = useTrackEvent('Schedule Your Exam');
  const trackOdpEvent = useTrackOdpEvent();

  const handleEvent = (e: MouseEvent<HTMLElement>) => {
    trackOdpEvent('navigation', 'Launchpad Button', 'Schedule Exam');
    const element = e.target as HTMLElement;
    trackEvent('Clicked', { label: element.innerText });
  };

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <h2>{t('ExamRegistration.Title')}</h2>
        {profile?.isBlocked ? (
          <p
            dangerouslySetInnerHTML={{
              __html: t('ExamRegistration.Blocked'),
            }}
          />
        ) : (
          <>
            <p>{t('ExamRegistration.Content')}</p>
            <List>
              <ListItem>{t('ExamRegistration.List-1')}</ListItem>
              <ListItem>{t('ExamRegistration.List-2')}</ListItem>
              <ListItem>{t('ExamRegistration.List-3')}</ListItem>
            </List>
            {env.FLAG_PEARSON_ENABLED ? (
              <>
                <Button
                  className={styles.button}
                  link
                  onClick={handleEvent}
                  to="/profile/pearson"
                >
                  {t('ExamRegistration.Button')}
                </Button>
                <p
                  className={styles.link}
                  dangerouslySetInnerHTML={{
                    __html: t('ExamRegistration.Accommodations'),
                  }}
                />
              </>
            ) : (
              <Button
                className={styles.button}
                link
                onClick={handleEvent}
                to="https://help.comptia.org"
              >
                {t('ExamRegistration.Button-2')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
