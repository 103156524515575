import { Button } from '@comptia-sso/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// Layouts.
import { ContentLayout } from 'layouts';

export const NotFoundView = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <ContentLayout
      subtitle={t('NotFound.Subheading')}
      title={t('NotFound.Heading')}
    >
      <Button link to="/">
        {t('NotFound.Button')}
      </Button>
    </ContentLayout>
  );
};
