import {
  Button,
  ButtonTypes,
  ButtonVariants,
  getGivenNameFromProfile,
} from '@comptia-sso/core';
import { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components.
import { EditEmail } from './EditEmail';
import { EditPassword } from './EditPassword';

// Hooks.
import { useProfile } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

// Styles.
import styles from './ProfileAccountView.module.scss';

export const ProfileAccountView = (): ReactElement => {
  const { t } = useTranslation();
  const [profile] = useProfile();
  const [mode, setMode] = useState<string>('ChangeEmail');

  return (
    <>
      <Helmet>
        <title>My Account</title>
      </Helmet>
      <ContentLayout
        subtitle={t('EditAccount.Subheading')}
        title={getGivenNameFromProfile(profile)}
      >
        <div className={styles.grid}>
          <Button
            type={ButtonTypes.Button}
            onClick={() => setMode('ChangeEmail')}
            variant={ButtonVariants.Text}
            className={mode === 'ChangeEmail' ? styles.active : ''}
          >
            {t('EditAccount.Email.Tab')}
          </Button>
          <Button
            type={ButtonTypes.Button}
            onClick={() => setMode('ChangePassword')}
            variant={ButtonVariants.Text}
            className={mode === 'ChangePassword' ? styles.active : ''}
          >
            {t('EditAccount.Password.Tab')}
          </Button>
        </div>
        <div className={styles.container}>
          {mode === 'ChangeEmail' && <EditEmail profile={profile} />}
          {mode === 'ChangePassword' && <EditPassword profile={profile} />}
        </div>
      </ContentLayout>
    </>
  );
};
