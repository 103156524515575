import { IAnnouncement } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getAnnouncements } from 'services';

type UseAnnouncements = [IAnnouncement[]];

export const useAnnouncements = (lang?: string): UseAnnouncements => {
  const language: string = !lang ? 'en' : lang;
  const { data } = useQuery<IAnnouncement[]>('announcements', () =>
    getAnnouncements(language),
  );

  return [data as IAnnouncement[]];
};
