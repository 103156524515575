import { Modal, IModalProps } from '@comptia-sso/core';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks.
import { useTrackModalview } from 'hooks';

// Styles.
import styles from './InstructionModal.module.scss';

export const InstructionModal = (props: IModalProps): ReactElement => {
  const { open } = props;
  const { t } = useTranslation();
  const trackModalview = useTrackModalview('redeem-access-key-instructions');

  useEffect(() => {
    if (open) {
      trackModalview();
    }
  }, [open, trackModalview]);

  return (
    <Modal
      {...props}
      className={styles.modal}
      title={t('RedeemInformation.Title')}
    >
      <p>{t('RedeemInformation.Content')}</p>
    </Modal>
  );
};
