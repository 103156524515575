import classNames from 'classnames';
import {
  ReactElement,
  MouseEvent,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Link as ScrollLink, LinkProps } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';

// Hooks.
import { useTrackEvent } from 'hooks';

// Styles.
import styles from '../Header.module.scss';

const navOffset = -70;
const scrollDuration = 1000;
let disableSetActive = false;

interface INavLinkProps extends Omit<LinkProps, 'onClick'> {
  isActive?: boolean;
  onClick: (e: MouseEvent<HTMLElement>) => void;
}

const NavLink = (props: INavLinkProps): ReactElement => {
  const { children, isActive, onClick, onSetActive, to } = props;
  const trackEvent = useTrackEvent('Header');

  const handleClick = useCallback(
    (e) => {
      trackEvent('Clicked', { label: e.target.innerText });
      onClick?.(e);
    },
    [onClick],
  );

  return (
    <ScrollLink
      className={classNames({ [styles.active]: isActive })}
      duration={scrollDuration}
      href={`#${to}`}
      id={`${to}-link`}
      offset={navOffset}
      /* eslint-disable-next-line */
      // @ts-ignore
      onClick={handleClick}
      onSetActive={onSetActive}
      smooth
      spy
      to={to}
    >
      {children}
    </ScrollLink>
  );
};

export const Nav = (): ReactElement => {
  const { t } = useTranslation();
  const [active, setActive] = useState<string>('examRegistration');
  const navRef = useRef<HTMLElement>(null);
  const links = useMemo(
    () => [
      {
        id: 'examRegistration',
        label: t('Navigation.Link-1'),
      },
      {
        id: 'pearsonVueDashboard',
        label: t('Navigation.Link-2'),
      },
      {
        id: 'certMetricsAccount',
        label: t('Navigation.Link-3'),
      },
      {
        id: 'accessYourTraining',
        label: t('Navigation.Link-4'),
      },
      {
        id: 'redeemAccessKeys',
        label: t('Navigation.Link-5'),
      },
    ],
    [t],
  );

  const handleSetActive = (id: string) => {
    setActive(id);
    const element = document.getElementById(`${id}-link`);
    if (!disableSetActive && element) {
      setNavPosition(element.offsetLeft);
    }
  };

  const handleLinkClick = (e: MouseEvent<HTMLElement>) => {
    disableSetActive = true;
    setTimeout(() => {
      disableSetActive = false;
    }, scrollDuration + 300);
    const element = e.target as HTMLElement;
    setNavPosition(element.offsetLeft);
  };

  const setNavPosition = (scrollLeft: number) => {
    if (navRef.current) {
      navRef.current.scrollLeft = scrollLeft;
    }
  };

  return (
    <nav className={styles.nav} ref={navRef}>
      <ul>
        <Routes>
          <Route
            element={
              <>
                {links.map((link) => (
                  <li key={link.id}>
                    <NavLink
                      isActive={active === link.id}
                      onClick={handleLinkClick}
                      onSetActive={handleSetActive}
                      to={link.id}
                    >
                      {link.label}
                    </NavLink>
                  </li>
                ))}
              </>
            }
            path="/"
          />
          <Route
            element={
              <li>
                <Link to="/">{t('Navigation.Link-Return')}</Link>
              </li>
            }
            path="*"
          />
        </Routes>
      </ul>
    </nav>
  );
};
