import { IProfile } from '@comptia-sso/core';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

// Config.
import { env } from 'config';

// Services.
import { getCertmetricsTokens } from 'services';

export const useRedirectToCertMetrics = (): ((returnUrl?: string) => void) => {
  const queryClient = useQueryClient();

  return useCallback(async (returnUrl?: string) => {
    const profile = queryClient.getQueryData<IProfile>('profile');
    let location = '';

    if (profile?.ssoId) {
      const token: string = await getCertmetricsTokens(profile.ssoId);
      location = env.CERTMETRICS_SSO_HOST.replace('{token}', token);
      if (returnUrl != null) {
        location = location.concat(`&ReturnUrl=${returnUrl}`);
      }
      window.location.href = location;
    }
  }, []);
};
