import { useQueryParams } from '@comptia-sso/core';
import { ReactElement } from 'react';

// Components.
import { FulfillmentBanner } from './FulfillmentBanner';
import { VerifyAddressRedirect } from './VerifyAddressRedirect';

export const AddressVerification = (): ReactElement => {
  const { verify_return_url: verifyReturnUrl } = useQueryParams();

  return verifyReturnUrl ? (
    <VerifyAddressRedirect verifyReturnUrl={verifyReturnUrl} />
  ) : (
    <FulfillmentBanner />
  );
};
