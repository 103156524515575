import { Button, List, ListItem } from '@comptia-sso/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrackOdpEvent } from 'hooks';

// Styles.
import styles from 'views/HomeView/HomeView.module.scss';

export const NeedHelp = (): ReactElement => {
  const { t } = useTranslation();
  const trackOdpEvent = useTrackOdpEvent();

  const handleEvent = () => {
    trackOdpEvent('navigation', 'Launchpad Button', 'Get Help');
  };

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <h2>{t('NeedHelp.Title')}</h2>
        <p>{t('NeedHelp.Content')}</p>
        <List>
          <ListItem>{t('NeedHelp.List-1')}</ListItem>
          <ListItem>{t('NeedHelp.List-2')}</ListItem>
          <ListItem>{t('NeedHelp.List-3')}</ListItem>
        </List>
        <Button
          className={styles.button}
          link
          onClick={handleEvent}
          to={t('FooterColumn-1-Links.Link-1')}
        >
          {t('NeedHelp.Button')}
        </Button>
      </div>
    </div>
  );
};
