import { IEntitlement, IError, IQueryHookOptions } from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { redeemAccessKey } from 'services';

type RedeemAccessKeyFunction = UseMutateAsyncFunction<
  IEntitlement,
  IError,
  string
>;

type UseRedeemAccessKey = [RedeemAccessKeyFunction, IQueryHookOptions];

export const useRedeemAccessKey = (id = ''): UseRedeemAccessKey => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IEntitlement,
    IError,
    string
  >((key: string) => redeemAccessKey({ accessKey: key, userId: id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['profile', 'entitlements']);
    },
  });

  return [mutateAsync, { error, isError, isLoading }];
};
