import { IError, IProfile, IQueryHookOptions } from '@comptia-sso/core';
import { useAccount, useMsal } from '@azure/msal-react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

// Services.
import { getProfile } from 'services';

type UseProfile = [IProfile, IQueryHookOptions];

export const useProfile = (): UseProfile => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const id = useMemo(() => {
    const idTokenClaims = account?.idTokenClaims as { id: string };
    return idTokenClaims?.id;
  }, [account]);

  const { data, error, isError, isLoading } = useQuery<IProfile, IError>(
    'profile',
    () => getProfile(id),
    {
      enabled: !!id,
    },
  );

  return [data as IProfile, { error, isError, isLoading }];
};
