import { IProfile, useQueryParams } from '@comptia-sso/core';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

// Config.
import { env } from 'config';

// Services.
import { getZendeskTokens } from 'services';

export const useRedirectToZendesk = (): VoidFunction => {
  const queryClient = useQueryClient();
  const { returnto } = useQueryParams();

  return useCallback(async () => {
    const profile = queryClient.getQueryData<IProfile>('profile');
    if (profile?.id) {
      const token: string = await getZendeskTokens(profile.id);
      let location: string;
      if (returnto != null) {
        location = env.ZENDESK_SSO_HOST + '?return_to=' + returnto;
      } else {
        location = env.ZENDESK_SSO_HOST;
      }

      // Create Zendesk SSO Form.
      const zendDeskForm = document.createElement('form');
      zendDeskForm.action = location;
      zendDeskForm.method = 'POST';
      zendDeskForm.name = 'ZenDesk Form';

      // Add CLIENT_CODE to form.
      const clientCode = document.createElement('input');
      clientCode.name = 'jwt';
      clientCode.type = 'hidden';
      clientCode.value = token;
      zendDeskForm.appendChild(clientCode);

      // Add form to document.
      document.body.appendChild(zendDeskForm);

      // Submit form.
      await zendDeskForm.submit();
    }
  }, []);
};
