import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { LoadingOverlay } from '@comptia-sso/core';
import { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// Config.
import { msalConfig, msalRequest } from 'config';

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((event: EventMessage) => {
  // Type Casting because the underlying types don't work properly.
  const eventType = event.eventType as EventType;
  const payload = event.payload as AuthenticationResult;

  if (eventType === EventType.LOGIN_SUCCESS && payload?.account) {
    msalInstance.setActiveAccount(payload?.account);
  } else if (eventType === EventType.LOGIN_FAILURE) {
    msalInstance.loginRedirect(msalRequest);
  }
});

export const AuthProvider = (
  props: PropsWithChildren<unknown>,
): ReactElement => {
  const { children } = props;
  const { t } = useTranslation();
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        authenticationRequest={msalRequest}
        interactionType={InteractionType.Redirect}
        loadingComponent={() => (
          <LoadingOverlay
            animateOnEnter={false}
            isOpen
            text={t('Loading.Default')}
          />
        )}
      >
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
