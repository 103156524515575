import { IAddress, IError, IQueryHookOptions } from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { verifyFulfillments } from 'services';

type VerifyFulfillmentsFunction = UseMutateAsyncFunction<
  boolean,
  IError,
  Partial<IAddress>
>;

type UseVerifyFulfillments = [VerifyFulfillmentsFunction, IQueryHookOptions];

export const useVerifyFulfillments = (id = ''): UseVerifyFulfillments => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    boolean,
    IError,
    Partial<IAddress>
  >((values: Partial<IAddress>) => verifyFulfillments(id, values), {
    onSuccess: () => {
      queryClient.invalidateQueries('hasPendingFulfillments');
    },
  });

  return [mutateAsync, { error, isError, isLoading }];
};
