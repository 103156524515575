import { useCallback } from 'react';

// Config.
import { env } from 'config';

export const useRedirectToCertMasterPractice = (): ((
  classroom?: string,
  target?: string,
) => void) => {
  return useCallback((classroom?: string, target?: string) => {
    window.open(
      [
        `${env.CERTMASTER_PRACTICE_SSO_HOST}`,
        [classroom && `classroom=${encodeURIComponent(classroom)}`]
          .filter(Boolean)
          .join('&'),
      ]
        .filter(Boolean)
        .join('?'),
      target || '_blank',
    );
  }, []);
};
