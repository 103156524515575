import classNames from 'classnames';
import { Button } from '@comptia-sso/core';
import { ReactElement, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';

// Icons.
import AdjustIcon from '@material-ui/icons/Adjust';
import FlareIcon from '@material-ui/icons/Flare';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LockIcon from '@material-ui/icons/Lock';

// Styles.
import styles from './Onboarding.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export interface IOnboardingProps {
  onClose?: VoidFunction;
}

const slideClasses = [
  styles.onboardingSecure,
  styles.onboardingConnected,
  styles.onboardingSimple,
];

export const Onboarding = (props: IOnboardingProps): ReactElement => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const { onClose: handleClose } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      <div
        ref={dropdownRef}
        className={classNames(styles.onboarding, slideClasses[currentIndex])}
      >
        <div className={styles.closeButton}>
          <HighlightOffIcon onClick={handleClose} />
        </div>
        <Carousel
          showArrows={false}
          showStatus={false}
          onChange={(index) => setCurrentIndex(index)}
          selectedItem={currentIndex}
          showThumbs={false}
        >
          {/* Item */}
          <div className={styles.item}>
            <div className={classNames(styles.card, styles.cardSecure)}>
              <div className={styles.cardFront}>
                <div className={styles.cardIcon}>
                  <LockIcon />
                </div>

                <Button
                  className={classNames(
                    styles.learnMore,
                    styles.learnMoreSecure,
                  )}
                >
                  {t('OnboardingSecure.Button')}
                </Button>
                <div className={styles.title}>
                  {t('OnboardingSecure.Title')}
                </div>
                <p className={styles.description}>
                  {t('OnboardingSecure.Content')}
                </p>
              </div>
              <div className={styles.cardBack}>
                <div className={styles.title}>
                  {t('OnboardingSecure.Title-2')}
                </div>
                <p className={styles.description}>
                  {t('OnboardingSecure.Content-2')}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={classNames(styles.card, styles.cardConnected)}>
              <div className={styles.cardFront}>
                <div className={styles.cardIcon}>
                  <FlareIcon />
                </div>

                <Button
                  className={classNames(
                    styles.learnMore,
                    styles.learnMoreConnected,
                  )}
                >
                  {t('OnboardingConnected.Button')}
                </Button>
                <div className={styles.title}>
                  {t('OnboardingConnected.Title')}
                </div>
                <p className={styles.description}>
                  {t('OnboardingConnected.Content')}
                </p>
              </div>
              <div className={styles.cardBack}>
                <div className={styles.title}>
                  {t('OnboardingConnected.Title-2')}
                </div>
                <p className={styles.description}>
                  {t('OnboardingConnected.Content-2')}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={classNames(styles.card, styles.cardSimple)}>
              <div className={styles.cardFront}>
                <div className={styles.cardIcon}>
                  <AdjustIcon />
                </div>

                <Button
                  className={classNames(
                    styles.learnMore,
                    styles.learnMoreSimple,
                  )}
                >
                  {t('OnboardingSimple.Button')}
                </Button>
                <div className={styles.title}>
                  {t('OnboardingSimple.Title')}
                </div>
                <p className={styles.description}>
                  {t('OnboardingSimple.Content')}
                </p>
              </div>
              <div className={styles.cardBack}>
                <div className={styles.title}>
                  {t('OnboardingSimple.Title-2')}
                </div>
                <p className={styles.description}>
                  {t('OnboardingSimple.Content-2')}
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
};
