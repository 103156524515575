import { useMsal } from '@azure/msal-react';
import { msalRequest } from 'config';
import { useCallback } from 'react';

export const useAuthLogout = (): ((postLogoutRedirectUri?: string) => void) => {
  const { instance } = useMsal();
  return useCallback(
    async (postLogoutRedirectUri?: string) => {
      const account = instance.getActiveAccount();
      if (account) {
        const token = await instance.acquireTokenSilent({
          ...msalRequest,
          account,
        });
        instance.logoutRedirect({
          idTokenHint: token.idToken,
          postLogoutRedirectUri,
        });
      }
    },
    [instance],
  );
};
