import { useCallback } from 'react';

// Config.
import { env } from 'config';

export const useRedirectToPopuli = (): ((target?: string) => void) => {
  return useCallback((target?: string) => {
    window.open(`${env.POPULI_SSO_HOST}`, target || '_blank');
  }, []);
};
