import {
  FindInPage,
  FormButton,
  FormTextField,
  IError,
  IModalProps,
  IProfile,
  IUpdateCertMasterPracticeProfile,
  Modal,
  ModalVariants,
  countries,
  getPrimaryAddressFromProfile,
  useToast,
} from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks.
import { useTrackEvent } from 'hooks';

// Styles.
import styles from './DetailsModal.module.scss';

// Validations.
import { getValidationSchema } from './validationSchema';

export interface IDetailsModalProps extends IModalProps {
  onSubmit: (values: IUpdateCertMasterPracticeProfile) => void;
  profile?: IProfile;
}

export const DetailsModal = (props: IDetailsModalProps): ReactElement => {
  const { onSubmit, profile, ...modalProps } = props;
  const trackEvent = useTrackEvent('Redeem Access Keys');
  const [popToast] = useToast();
  const { t } = useTranslation();
  const primaryAddress = useMemo(
    () => getPrimaryAddressFromProfile(profile),
    [profile],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company: profile?.company || '',
      country: primaryAddress?.country || 'USA',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        trackEvent('Entered Additional Details');
        await onSubmit(values);
        resetForm();
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(t),
  });

  return (
    <Modal {...modalProps} variant={ModalVariants.Small}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <div className={styles.grid}>
            <div className={styles.iconContainer}>
              <FindInPage width={56} height={56} />
            </div>
            <p>{t('RedeemModule.Details.Content')}</p>
            <FormTextField label={t('Profile.Company')} name="company" />
            <FormTextField
              label={t('Profile.Address.Country')}
              name="country"
              required
              select
            >
              {countries?.map((country) => (
                <option key={country.abbreviation} value={country.abbreviation}>
                  {country.name}
                </option>
              ))}
            </FormTextField>
            <FormButton className={styles.button}>
              {t('RedeemModule.Details.Cta')}
            </FormButton>
          </div>
        </FormikProvider>
      </form>
    </Modal>
  );
};
