import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { changeEmail } from 'services';

// Types.
import { IChangeEmailRequest } from 'types';

type ChangeEmailFunction = UseMutateAsyncFunction<
  void,
  IError,
  IChangeEmailRequest
>;

type UseChangeEmail = [ChangeEmailFunction, IQueryHookOptions];

export const useChangeEmail = (id = ''): UseChangeEmail => {
  const { error, isError, isLoading, mutateAsync } = useMutation<
    void,
    IError,
    IChangeEmailRequest
  >((values: IChangeEmailRequest) => changeEmail(id, values));

  return [mutateAsync, { error, isError, isLoading }];
};
