import { IProfile, yup } from '@comptia-sso/core';
import { TFunction } from 'react-i18next';

export const getValidationSchema = (
  t: TFunction,
  profile: IProfile,
): yup.SchemaOf<unknown> =>
  yup.object().shape({
    allowConsentToSendSmsTextMessages: yup.boolean(),
    alternateEmail: yup
      .string()
      .isValidEmail(t)
      .test('emails-match', t('Validation.PrimaryEmail'), function (value) {
        return profile.email !== value;
      }),
    company: yup.string().isValidCompany(t),
    givenName: yup.object().shape({
      firstName: yup
        .string()
        .required(t('Validation.Required'))
        .isValidGivenFirstName(t)
        .isNameNotAnEmail(t),
      lastName: yup
        .string()
        .required(t('Validation.Required'))
        .isValidGivenLastName(t)
        .isNameNotAnEmail(t),
      middleName: yup.string().isValidGivenMiddleName(t).isNameNotAnEmail(t),
      suffix: yup.string().isValidGivenSuffix(t),
    }),
    mobilePhoneNumber: yup.object().shape({
      countryCode: yup.string().isValidPhoneNumberCountryCode(t),
      phone: yup
        .string()
        .isValidPhoneNumber(t)
        .test(
          'consent-to-sms-selected',
          t('Validation.validMobilePhoneNumber'),
          function (value) {
            // eslint-disable-next-line
            // @ts-ignore
            const [, rootSchema] = this.from;
            const { allowConsentToSendSmsTextMessages } =
              rootSchema?.value || {};

            if (!allowConsentToSendSmsTextMessages) return true;

            return (
              allowConsentToSendSmsTextMessages &&
              value !== undefined &&
              value?.length > 0
            );
          },
        ),
    }),
    preferredName: yup.object().shape({
      firstName: yup.string().isValidPreferredFirstName(t).isNameNotAnEmail(t),
      lastName: yup.string().isValidPreferredLastName(t).isNameNotAnEmail(t),
    }),
    primaryAddress: yup.object().shape({
      address1: yup
        .string()
        .required(t('Validation.Required'))
        .isValidAddressLine1(t),
      address2: yup.string().isValidAddressLine2(t),
      address3: yup.string().isValidAddressLine3(t),
      city: yup.string().required(t('Validation.Required')).isValidCity(t),
      country: yup
        .string()
        .required(t('Validation.Required'))
        .isValidCountry(t),
      postalCode: yup
        .string()
        .isValidPostalCode(t)
        .when('country', {
          is: (value: string) => ['CAN', 'USA'].includes(value),
          then: yup.string().required(t('Validation.Required')),
        }),
      state: yup
        .string()
        .isValidState(t)
        .when('country', {
          is: (value: string) => ['CAN', 'USA'].includes(value),
          then: yup.string().required(t('Validation.Required')),
        }),
    }),
    primaryPhoneNumber: yup.object().shape({
      countryCode: yup
        .string()
        .required(t('Validation.Required'))
        .isValidPhoneNumberCountryCode(t),
      phone: yup
        .string()
        .required(t('Validation.Required'))
        .isValidPhoneNumber(t),
    }),
    secondaryPhoneNumber: yup.object().shape({
      countryCode: yup
        .string()
        .isValidPhoneNumberCountryCode(t)
        .test(
          'phone-matches',
          t('Validation.PrimaryPhoneNumber'),
          function (value) {
            // eslint-disable-next-line
            // @ts-ignore
            const [, rootSchema] = this.from;
            const { primaryPhoneNumber, secondaryPhoneNumber } =
              rootSchema?.value || {};

            if (!primaryPhoneNumber.phone) return true;

            return (
              primaryPhoneNumber.countryCode !== value ||
              primaryPhoneNumber.phone !== secondaryPhoneNumber.phone
            );
          },
        ),
      phone: yup
        .string()
        .isValidPhoneNumber(t)
        .test(
          'phone-matches',
          t('Validation.PrimaryPhoneNumber'),
          function (value) {
            // eslint-disable-next-line
            // @ts-ignore
            const [, rootSchema] = this.from;
            const { primaryPhoneNumber, secondaryPhoneNumber } =
              rootSchema?.value || {};

            if (!primaryPhoneNumber.phone) return true;

            return (
              primaryPhoneNumber.countryCode !==
                secondaryPhoneNumber.countryCode ||
              primaryPhoneNumber.phone !== value
            );
          },
        ),
    }),
  });
