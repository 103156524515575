import { IAddress, IError, LoadingOverlay, useToast } from '@comptia-sso/core';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components.
import { AddressVerificationModal } from '../AddressVerificationModal';

// Hooks.
import { useProfile, useVerifyFulfillments } from 'hooks';

interface IVerifyAddressRedirect {
  verifyReturnUrl: string;
}

export const VerifyAddressRedirect = (
  props: IVerifyAddressRedirect,
): ReactElement => {
  const { verifyReturnUrl } = props;
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [profile] = useProfile();
  const [verifyFulfillments, { isLoading: isUpdating }] = useVerifyFulfillments(
    profile?.id,
  );
  const [isVerifying, setIsVerifying] = useState<boolean>(!!verifyReturnUrl);

  const handleClose = useCallback(() => {
    setIsVerifying(false);
  }, []);

  const handleSubmit = useCallback(
    async (values: Partial<IAddress>) => {
      try {
        await verifyFulfillments(values);
        popToast(t('Toast.Success.EditProfile'));
        handleClose();
        window.location.href = verifyReturnUrl;
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
    },
    [verifyReturnUrl],
  );

  return (
    <>
      <AddressVerificationModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={isVerifying}
        profile={profile}
      />
      <LoadingOverlay isOpen={isUpdating} text={t('Loading.UpdatingProfile')} />
    </>
  );
};
