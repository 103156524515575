import {
  IError,
  IProfile,
  IQueryHookOptions,
  IUpdateProfileRequest,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { updateProfileCandidate } from 'services';

type UpdateProfileCandidateFunction = UseMutateAsyncFunction<
  IProfile,
  IError,
  IUpdateProfileRequest
>;

type UseUpdateProfileCandidate = [
  UpdateProfileCandidateFunction,
  IQueryHookOptions,
];

export const useUpdateProfileCandidate = (
  id = '',
): UseUpdateProfileCandidate => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IProfile,
    IError,
    IUpdateProfileRequest
  >((values: IUpdateProfileRequest) => updateProfileCandidate(id, values), {
    onSuccess: (data) => {
      queryClient.setQueryData('profile', data);
    },
  });

  return [mutateAsync, { error, isError, isLoading }];
};
