const isDev = process.env.NODE_ENV === 'development';

export const env = {
  APP_INSIGHTS_CONNECTION_STRING: process.env
    .REACT_APP_APP_INSIGHTS_CONNECTION_STRING as string,
  B2C_CLIENT_ID: process.env.REACT_APP_B2C_CLIENT_ID as string,
  B2C_DOMAIN: process.env.REACT_APP_B2C_DOMAIN as string,
  B2C_POLICY: process.env.REACT_APP_B2C_POLICY as string,
  B2C_TENANT: process.env.REACT_APP_B2C_TENANT as string,
  BENCHPREP_SSO_HOST: process.env.REACT_APP_BENCHPREP_SSO_HOST as string,
  CANVAS_SSO_HOST: process.env.REACT_APP_CANVAS_SSO_HOST as string,
  CERTMASTER_PRACTICE_SSO_HOST: process.env
    .REACT_APP_CERTMASTER_PRACTICE_SSO_HOST as string,
  CERTMETRICS_SSO_HOST: process.env.REACT_APP_CERTMETRICS_SSO_HOST as string,
  ENTITLEMENTS_API_HOST: isDev
    ? '/entitlements'
    : (process.env.REACT_APP_ENTITLEMENTS_API_HOST as string),
  ENTITLEMENTS_API_KEY: process.env.REACT_APP_ENTITLEMENTS_API_KEY as string,
  FLAG_CERTMETRICS_ENABLED: /true/.test(
    process.env.REACT_APP_FLAG_CERTMETRICS_ENABLED as string,
  ),
  FLAG_LEARNING_SYSTEMS_ENABLED: /true/.test(
    process.env.REACT_APP_FLAG_LEARNING_SYSTEMS_ENABLED as string,
  ),
  FLAG_ODP_ENABLED: /true/.test(
    process.env.REACT_APP_FLAG_ODP_ENABLED as string,
  ),
  FLAG_PEARSON_ENABLED: /true/.test(
    process.env.REACT_APP_FLAG_PEARSON_ENABLED as string,
  ),
  GA_ID: process.env.REACT_APP_GA_ID as string,
  GUTENBERG_SSO_HOST: process.env.REACT_APP_GUTENBERG_SSO_HOST as string,
  IDENTITY_API_HOST: isDev
    ? '/identity'
    : (process.env.REACT_APP_IDENTITY_API_HOST as string),
  IDENTITY_API_KEY: process.env.REACT_APP_IDENTITY_API_KEY as string,
  ODP_KEY: process.env.REACT_APP_ODP_KEY as string,
  PEARSON_SSO_HOST: process.env.REACT_APP_PEARSON_SSO_HOST as string,
  POPULI_SSO_HOST: process.env.REACT_APP_POPULI_SSO_HOST as string,
  ZENDESK_SSO_HOST: process.env.REACT_APP_ZENDESK_SSO_HOST as string,
  ZENDESK_WIDGET_KEY: process.env.REACT_APP_ZENDESK_WIDGET_KEY as string,
};
