import { yup } from '@comptia-sso/core';
import { TFunction } from 'react-i18next';

export const getValidationSchema = (t: TFunction): yup.SchemaOf<unknown> =>
  yup.object().shape({
    currentPassword: yup.string().required(t('Validation.Required')),
    newPassword: yup
      .string()
      .required(t('Validation.Required'))
      .isValidPassword(t)
      .test(
        'passwords-match',
        t('Validation.CurrentPassword'),
        function (value) {
          return this.parent.currentPassword !== value;
        },
      ),
    newPasswordConfirmation: yup
      .string()
      .required('Required')
      .test(
        'passwords-match',
        t('Validation.PasswordsMatch'),
        function (value) {
          return this.parent.newPassword === value;
        },
      ),
  });
