import { IProfile, yup } from '@comptia-sso/core';
import { TFunction } from 'react-i18next';

export const getSendEmailVerificationValidationSchema = (
  t: TFunction,
  profile: IProfile,
): yup.SchemaOf<unknown> =>
  yup.object().shape({
    currentPassword: yup.string().required(t('Validation.Required')),
    email: yup
      .string()
      .isValidEmail(t)
      .test('current-email', t('Validation.CurrentEmail'), function (value) {
        return value !== profile?.email;
      })
      .test(
        'alternate-email',
        t('Validation.AlternateEmail'),
        function (value) {
          return value !== profile?.alternateEmail;
        },
      ),
  });

export const getChangeEmailValidationSchema = (
  t: TFunction,
): yup.SchemaOf<unknown> =>
  yup.object().shape({
    verificationCode: yup.string().required(t('Validation.Required')),
  });
