import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { sendVerificationCode } from 'services';

// Types.
import { ISendVerificationEmailRequest } from 'types';

type SendEmailVerificationCodeFunction = UseMutateAsyncFunction<
  void,
  IError,
  ISendVerificationEmailRequest
>;

type UseSendEmailVerificationCode = [
  SendEmailVerificationCodeFunction,
  IQueryHookOptions,
];

export const useSendEmailVerificationCode = (
  id = '',
): UseSendEmailVerificationCode => {
  const { error, isError, isLoading, mutateAsync } = useMutation<
    void,
    IError,
    ISendVerificationEmailRequest
  >((values: ISendVerificationEmailRequest) =>
    sendVerificationCode(id, values),
  );

  return [mutateAsync, { error, isError, isLoading }];
};
