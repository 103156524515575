import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { resendVerificationCode } from 'services';

type ResendEmailVerificationCodeFunction = UseMutateAsyncFunction<
  void,
  IError,
  null
>;

type UseResendEmailVerificationCode = [
  ResendEmailVerificationCodeFunction,
  IQueryHookOptions,
];

export const useResendEmailVerificationCode = (
  id = '',
): UseResendEmailVerificationCode => {
  const { error, isError, isLoading, mutateAsync } = useMutation<
    void,
    IError,
    null
  >(() => resendVerificationCode(id));

  return [mutateAsync, { error, isError, isLoading }];
};
