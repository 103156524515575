import classNames from 'classnames';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

// Styles.
import styles from './ContentLayout.module.scss';

interface IContentLayoutProps {
  subtitle?: ReactNode;
  title: string;
}

export const ContentLayout = (
  props: PropsWithChildren<IContentLayoutProps>,
): ReactElement => {
  const { children, subtitle, title } = props;

  return (
    <div className={styles.container}>
      <header
        className={classNames(styles.header, {
          [styles['has-subtitle']]: !!subtitle,
        })}
      >
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </header>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
