import { IProfile } from '@comptia-sso/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Config.
import { env } from 'config';

// Services.
import { getPearsonTokens } from 'services';

// Types.
import { IPearsonTokens } from 'types';

export enum PearsonActions {
  ViewExamCatalog = 'viewExamCatalog',
}

export const useRedirectToPearson = (): ((
  pearsonAction?: PearsonActions,
) => void) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const localeValue = useMemo(
    () => (i18n.language === 'ja' ? 'ja_JP' : 'en_US'),
    [i18n.language],
  );

  return useCallback(
    async (actionId?: PearsonActions) => {
      const profile = queryClient.getQueryData<IProfile>('profile');

      if (!profile?.externalIds?.pearsonVue) {
        navigate('/profile/pearson');
      } else {
        const tokens: IPearsonTokens = await getPearsonTokens(profile.id);

        // Create Pearson SSO Form.
        const pearsonForm = document.createElement('form');
        pearsonForm.action = env.PEARSON_SSO_HOST;
        pearsonForm.method = 'POST';
        pearsonForm.name = 'Pearson Registration';

        // Add CLIENT_CODE to form.
        const clientCode = document.createElement('input');
        clientCode.name = 'CLIENT_CODE';
        clientCode.type = 'hidden';
        clientCode.value = tokens.clientCode;
        pearsonForm.appendChild(clientCode);

        // Add VUE_CANDIDATE_ID to form.
        const vueCandidateId = document.createElement('input');
        vueCandidateId.name = 'VUE_CANDIDATE_ID';
        vueCandidateId.type = 'hidden';
        vueCandidateId.value = profile.externalIds.pearsonVue;
        pearsonForm.appendChild(vueCandidateId);

        // Add EXTERNAL_PAGE_TIMESTAMP to form.
        const externalPageTimestamp = document.createElement('input');
        externalPageTimestamp.name = 'EXTERNAL_PAGE_TIMESTAMP';
        externalPageTimestamp.type = 'hidden';
        externalPageTimestamp.value = String(tokens.externalPageTimestamp);
        pearsonForm.appendChild(externalPageTimestamp);

        // Add EXTERNAL_ERROR_URL to form.
        const externalErrorUrl = document.createElement('input');
        externalErrorUrl.name = 'EXTERNAL_ERROR_URL';
        externalErrorUrl.type = 'hidden';
        externalErrorUrl.value = window.location.origin;
        pearsonForm.appendChild(externalErrorUrl);

        // Add EXTERNAL_LOGOUT_URL to form.
        const externalLogoutUrl = document.createElement('input');
        externalLogoutUrl.name = 'EXTERNAL_LOGOUT_URL';
        externalLogoutUrl.type = 'hidden';
        externalLogoutUrl.value = window.location.origin;
        pearsonForm.appendChild(externalLogoutUrl);

        // Add EXTERNAL_RETURN_URL to form.
        const externalReturnUrl = document.createElement('input');
        externalReturnUrl.name = 'EXTERNAL_RETURN_URL';
        externalReturnUrl.type = 'hidden';
        externalReturnUrl.value = window.location.origin;
        pearsonForm.appendChild(externalReturnUrl);

        // Add EXTERNAL_TIMEOUT_URL to form.
        const externalTimeoutUrl = document.createElement('input');
        externalTimeoutUrl.name = 'EXTERNAL_TIMEOUT_URL';
        externalTimeoutUrl.type = 'hidden';
        externalTimeoutUrl.value = window.location.origin;
        pearsonForm.appendChild(externalTimeoutUrl);

        // Add EXTERNAL_SESSION_TIMEOUT to form.
        const externalSessionTimeout = document.createElement('input');
        externalSessionTimeout.name = 'EXTERNAL_SESSION_TIMEOUT';
        externalSessionTimeout.type = 'hidden';
        externalSessionTimeout.value = String(tokens.externalSessionTimeout);
        pearsonForm.appendChild(externalSessionTimeout);

        // Add LOCALE_CODE to form.
        const localeCode = document.createElement('input');
        localeCode.name = 'LOCALE_CODE';
        localeCode.type = 'hidden';
        localeCode.value = localeValue;
        pearsonForm.appendChild(localeCode);

        // Add HIDE_CONFIRM_PERSONAL_INFO_PAGE to form.
        const hideConfirmPersonalInfoPage = document.createElement('input');
        hideConfirmPersonalInfoPage.name = 'HIDE_CONFIRM_PERSONAL_INFO_PAGE';
        hideConfirmPersonalInfoPage.type = 'hidden';
        hideConfirmPersonalInfoPage.value = 'True';
        pearsonForm.appendChild(hideConfirmPersonalInfoPage);

        if (actionId) {
          // Add ACTION to form.
          const action = document.createElement('input');
          action.name = 'ACTION';
          action.type = 'hidden';
          action.value = actionId;
          pearsonForm.appendChild(action);
        }

        // Add EXTERNAL_AUTH_HASH to form.
        const externalAuthHash = document.createElement('input');
        externalAuthHash.name = 'EXTERNAL_AUTH_HASH';
        externalAuthHash.type = 'hidden';
        externalAuthHash.value = tokens.token;
        pearsonForm.appendChild(externalAuthHash);

        // Add form to document.
        document.body.appendChild(pearsonForm);

        // Submit form.
        await pearsonForm.submit();
      }
    },
    [navigate],
  );
};
