import {
  Button,
  ButtonTypes,
  FormTextField,
  IError,
  IProfile,
  LoadingOverlay,
  TextFieldTypes,
  useToast,
} from '@comptia-sso/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks.
import { useSetPassword, useTrackEvent, useTrackOdpEvent } from 'hooks';

// Styles.
import styles from './EditPassword.module.scss';

// Validations.
import { getValidationSchema } from './validationSchema';

interface IEditPasswordProps {
  profile?: IProfile;
}

export const EditPassword = (props: IEditPasswordProps): ReactElement => {
  const { profile } = props;
  const trackEvent = useTrackEvent('Edit Password');
  const trackOdpEvent = useTrackOdpEvent();
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState<boolean>(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] =
    useState<boolean>(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState<boolean>(false);
  const [setPassword] = useSetPassword(profile?.ssoId);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        trackEvent('Submitted');
        trackOdpEvent('account', 'update password', '');
        await setPassword({
          currentPassword: values.currentPassword,
          email: profile?.email || '',
          newPassword: values.newPassword,
        });
        popToast(t('Toast.Success.EditPassword'));
        setIsEditing(false);
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
      setIsSubmitting(false);
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(t),
  });

  useEffect(() => {
    if (!isEditing) {
      formik.resetForm();
    }
  }, [isEditing]);

  if (profile?.authenticationSource === 'socialIdpAuthentication') {
    return (
      <div className={styles.social}>
        <strong className={styles.heading}>
          {t('EditAccount.Password.Heading')}
        </strong>
        <span className={styles.text}>{t('EditAccount.Password.Social')}</span>
      </div>
    );
  }

  return (
    <>
      <small className={styles.required}>{t('EditAccount.Required')}</small>
      <form
        aria-label="edit password"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <FormikProvider value={formik}>
          <fieldset>
            <legend>
              {t('EditAccount.Password.Heading')}
              {isEditing ? (
                <button
                  aria-label="disable editing"
                  className={`${styles.edit} ${styles.cancel}`}
                  onClick={() => setIsEditing(false)}
                  type="button"
                >
                  {t('Button.Cancel')}
                </button>
              ) : (
                <button
                  aria-label="enable editing"
                  className={styles.edit}
                  onClick={() => setIsEditing(true)}
                  type="button"
                >
                  {t('Button.Edit')}
                </button>
              )}
            </legend>
            <div className={styles.grid}>
              <FormTextField
                disabled={!isEditing}
                endIcon={
                  isCurrentPasswordVisible ? (
                    <button
                      aria-label="hide current password"
                      disabled={!isEditing}
                      onClick={() => setIsCurrentPasswordVisible(false)}
                      type="button"
                    >
                      <Visibility />
                    </button>
                  ) : (
                    <button
                      aria-label="show current password"
                      disabled={!isEditing}
                      onClick={() => setIsCurrentPasswordVisible(true)}
                      type="button"
                    >
                      <VisibilityOff />
                    </button>
                  )
                }
                label={t('EditAccount.Password.CurrentPassword')}
                name="currentPassword"
                required
                type={
                  isCurrentPasswordVisible
                    ? TextFieldTypes.Text
                    : TextFieldTypes.Password
                }
              />
              <FormTextField
                disabled={!isEditing}
                endIcon={
                  isNewPasswordVisible ? (
                    <button
                      aria-label="hide new password"
                      disabled={!isEditing}
                      onClick={() => setIsNewPasswordVisible(false)}
                      type="button"
                    >
                      <Visibility />
                    </button>
                  ) : (
                    <button
                      aria-label="show new password"
                      disabled={!isEditing}
                      onClick={() => setIsNewPasswordVisible(true)}
                      type="button"
                    >
                      <VisibilityOff />
                    </button>
                  )
                }
                label={t('EditAccount.Password.NewPassword')}
                name="newPassword"
                required
                type={
                  isNewPasswordVisible
                    ? TextFieldTypes.Text
                    : TextFieldTypes.Password
                }
              />
              <FormTextField
                disabled={!isEditing}
                endIcon={
                  isConfirmPasswordVisible ? (
                    <button
                      aria-label="hide confirm new password"
                      disabled={!isEditing}
                      onClick={() => setIsConfirmPasswordVisible(false)}
                      type="button"
                    >
                      <Visibility />
                    </button>
                  ) : (
                    <button
                      aria-label="show confirm new password"
                      disabled={!isEditing}
                      onClick={() => setIsConfirmPasswordVisible(true)}
                      type="button"
                    >
                      <VisibilityOff />
                    </button>
                  )
                }
                label={t('EditAccount.Password.ConfirmNewPassword')}
                name="newPasswordConfirmation"
                required
                type={
                  isConfirmPasswordVisible
                    ? TextFieldTypes.Text
                    : TextFieldTypes.Password
                }
              />
            </div>
          </fieldset>
          <Button
            className={styles.button}
            disabled={!isEditing}
            type={ButtonTypes.Submit}
          >
            {t('EditAccount.Cta')}
          </Button>
        </FormikProvider>
        <LoadingOverlay
          isOpen={isSubmitting}
          text={t('Loading.UpdatingPassword')}
        />
      </form>
    </>
  );
};
