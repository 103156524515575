import {
  IError,
  LoadingOverlay,
  useBreakpoints,
  useQueryParams,
  useToast,
} from '@comptia-sso/core';
import { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

// Components.
import {
  AddressVerification,
  OptimizelyDataPlatform,
  PearsonErrorModal,
} from 'components';
import { Footer } from './Footer';
import { Header } from './Header';
import { Nav } from './Header/Nav';
import { ProfileMenu } from './Header/ProfileMenu';
import WootricSurvey from 'components/WootricSurvey';

// Config.
import { env } from 'config';

// Hooks.
import { useProfile } from 'hooks';

// Images.
import comptiaLogo from 'images/comptia-logo.svg';

// Styles.
import headerStyles from './Header/Header.module.scss';

// Views.
import {
  HomeView,
  LoginView,
  LogoutView,
  NotFoundView,
  ProfileAccountView,
  ProfileDetailsView,
  ProfilePearsonView,
  TrainingProductsView,
  HelpView,
} from 'views';

export const App = (): ReactElement => {
  const location = useLocation();
  const { isMobile } = useBreakpoints();
  const { s } = useQueryParams();
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [profile, { error: profileError, isLoading: isLoadingProfile }] =
    useProfile();

  // Scroll page to top when route changes.
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  useEffect(() => {
    if (profileError) {
      popToast((profileError as IError)?.message || t('Toast.Error.Default'));
    }
  }, [profileError]);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector<HTMLElement>(location.hash);
      if (element) {
        // Give enough time for document to size itself.
        setTimeout(() => {
          // Scroll to element with header offset.
          window.scrollTo({ top: element.offsetTop - 70 });
        }, 150);
      }
    }
  }, [isLoadingProfile, location.hash]);

  useEffect(() => {
    if (profile) {
      ZendeskAPI('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            departments: {
              enabled: [],
              select: 'CompTIA Support Team',
            },
          },
          // Set the allowed Zendesk Form Ids that will available to the chat widget
          contactForm: {
            ticketForms: [
              { id: 30517987199892 },
              { id: 30522109138580 },
              { id: 30522281666708 },
              { id: 30522432760340 },
              { id: 30522676806420 },
              { id: 30522848633620 },
              { id: 30523186553876 },
              { id: 30523435530260 },
              { id: 9564308100500 },
            ],
          },
        },
      });
      ZendeskAPI('webWidget', 'prefill', {
        email: {
          readOnly: true,
          value: profile.email,
        },
        name: {
          readOnly: true,
          value: profile.givenName.firstName + ' ' + profile.givenName.lastName,
        },
      });
    }
  }, [profile]);

  return (
    <>
      <Helmet titleTemplate="%s | CompTIA">
        <title>Launchpad</title>
      </Helmet>
      <AddressVerification />
      {isMobile ? (
        <>
          <header className={headerStyles.header}>
            <Link to="/" className={headerStyles.logo}>
              <img alt="CompTIA Logo" src={comptiaLogo} />
            </Link>
            <ProfileMenu />
          </header>
          <div className={headerStyles.mobileNav}>
            <Nav />
          </div>
        </>
      ) : (
        <Header />
      )}
      <main>
        <Routes>
          <Route element={<HomeView />} index />
          <Route element={<ProfileAccountView />} path="account" />
          <Route path="profile">
            <Route element={<ProfileDetailsView />} index />
            {env.FLAG_PEARSON_ENABLED && (
              <Route element={<ProfilePearsonView />} path="pearson" />
            )}
          </Route>
          {env.FLAG_LEARNING_SYSTEMS_ENABLED && (
            <Route
              element={<TrainingProductsView />}
              path="training-products"
            />
          )}
          <Route element={<NotFoundView />} path="*" />
        </Routes>
      </main>
      <Footer />
      <PearsonErrorModal
        errorCode={s as string}
        isDisabled={isLoadingProfile}
      />
      <LoadingOverlay
        animateOnEnter={false}
        isOpen={isLoadingProfile}
        text={t('Loading.Default')}
      />
      <Zendesk defer zendeskKey={env.ZENDESK_WIDGET_KEY} />
      <WootricSurvey />
      {env.FLAG_ODP_ENABLED && <OptimizelyDataPlatform />}
    </>
  );
};

export const AppWrapper = (): ReactElement => {
  return (
    <Routes>
      <Route element={<LoginView />} path="/login" />
      <Route element={<LogoutView />} path="/logout" />
      <Route element={<HelpView />} path="/help" />
      <Route element={<App />} path="*" />
    </Routes>
  );
};
