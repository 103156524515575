import {
  IAddress,
  IAnnouncement,
  IEntitlement,
  IProfile,
  IUpdateCertMasterPracticeProfile,
  IUpdateProfileRequest,
  constructProfilePatchRequest,
} from '@comptia-sso/core';

// Config.
import { env } from 'config';

// Services.
import { getRequest, patchRequest, postRequest } from './api';

// Types.
import {
  IChangeEmailRequest,
  IPearsonTokens,
  ISendVerificationEmailRequest,
  ISetPasswordRequest,
} from 'types';

export const changeEmail = (
  id: string,
  values: IChangeEmailRequest,
): Promise<void> =>
  postRequest<void, IChangeEmailRequest>(
    `${env.IDENTITY_API_HOST}/api/accounts/${id}/change-email`,
    values,
    env.IDENTITY_API_KEY,
  );

export const sendVerificationCode = (
  id: string,
  values: ISendVerificationEmailRequest,
): Promise<void> =>
  postRequest<void, ISendVerificationEmailRequest>(
    `${env.IDENTITY_API_HOST}/api/accounts/${id}/send-email-verification-code`,
    values,
    env.IDENTITY_API_KEY,
  );

export const resendVerificationCode = (id: string): Promise<void> =>
  postRequest<void, string>(
    `${env.IDENTITY_API_HOST}/api/accounts/${id}/resend-email-verification-code`,
    '',
    env.IDENTITY_API_KEY,
  );

export const createPearsonCandidate = (
  values: IUpdateProfileRequest,
): Promise<IProfile> => {
  const { primaryPhoneNumber, mobilePhoneNumber, ...rest } = values;
  const request = {
    ...rest,
    mobilephoneNumber: mobilePhoneNumber,
    phoneNumber: primaryPhoneNumber,
  };
  return postRequest<IProfile, IUpdateProfileRequest>(
    `${env.IDENTITY_API_HOST}/api/vendors/pearson/candidates`,
    request,
    env.IDENTITY_API_KEY,
  );
};

export const getCertmetricsTokens = (id: string): Promise<string> =>
  getRequest<string>(
    `${env.IDENTITY_API_HOST}/api/vendors/certmetrics/tokens/${id}`,
    env.IDENTITY_API_KEY,
  );

export const getHasPendingFulfillments = (id: string): Promise<boolean> =>
  getRequest(
    `${env.IDENTITY_API_HOST}/api/vendors/certmetrics/${id}/pendingfulfillments`,
    env.IDENTITY_API_KEY,
  );

export const getPearsonTokens = (id: string): Promise<IPearsonTokens> =>
  getRequest<IPearsonTokens>(
    `${env.IDENTITY_API_HOST}/api/vendors/pearson/tokens/${id}`,
    env.IDENTITY_API_KEY,
  );

export const getProfile = (id: string): Promise<IProfile> =>
  getRequest<IProfile>(
    `${env.IDENTITY_API_HOST}/api/profiles/account/${id}`,
    env.IDENTITY_API_KEY,
  );

export const getProfileEntitlements = (id: string): Promise<IEntitlement[]> =>
  getRequest(
    `${env.IDENTITY_API_HOST}/api/profiles/${id}/entitlements`,
    env.IDENTITY_API_KEY,
  );

export const setPassword = (
  id: string,
  values: ISetPasswordRequest,
): Promise<void> =>
  postRequest<void, ISetPasswordRequest>(
    `${env.IDENTITY_API_HOST}/api/accounts/${id}/set-password`,
    values,
    env.IDENTITY_API_KEY,
  );

export const updateProfile = (
  id: string,
  values: IUpdateProfileRequest,
): Promise<IProfile> => {
  return patchRequest<IProfile>(
    `${env.IDENTITY_API_HOST}/api/profiles/${id}`,
    constructProfilePatchRequest(values),
    env.IDENTITY_API_KEY,
  );
};

export const updateProfileCandidate = (
  id: string,
  values: IUpdateProfileRequest,
): Promise<IProfile> => {
  return patchRequest<IProfile>(
    `${env.IDENTITY_API_HOST}/api/profiles/${id}/candidate`,
    constructProfilePatchRequest(values),
    env.IDENTITY_API_KEY,
  );
};

export const updateProfilePopuli = (
  id: string,
  values: IUpdateProfileRequest,
): Promise<IProfile> => {
  return patchRequest<IProfile>(
    `${env.IDENTITY_API_HOST}/api/profiles/${id}/populi`,
    constructProfilePatchRequest(values),
    env.IDENTITY_API_KEY,
  );
};

export const updateCertMasterPracticeProfile = (
  profileId: string,
  values: IUpdateCertMasterPracticeProfile,
): Promise<IProfile> =>
  postRequest<IProfile, IUpdateCertMasterPracticeProfile>(
    `${env.IDENTITY_API_HOST}/api/profiles/${profileId}/cmp/profile`,
    values,
    env.IDENTITY_API_KEY,
  );

export const releaseFulfillments = (
  profileId: string,
  address: Partial<IAddress>,
): Promise<boolean> =>
  postRequest(
    `${env.IDENTITY_API_HOST}/api/vendors/certmetrics/releasefulfillments`,
    { address, profileId },
    env.IDENTITY_API_KEY,
  );

export const verifyFulfillments = (
  profileId: string,
  address: Partial<IAddress>,
): Promise<boolean> =>
  postRequest(
    `${env.IDENTITY_API_HOST}/api/vendors/certmetrics/verifyaddress`,
    { address, profileId },
    env.IDENTITY_API_KEY,
  );

export const getSkillableSSOUrl = (
  profileId: string,
): Promise<{ url: string }> =>
  getRequest(
    `${env.IDENTITY_API_HOST}/api/vendors/skillable/ssosurl/${profileId}`,
    env.IDENTITY_API_KEY,
  );

export const getZendeskTokens = (id: string): Promise<string> =>
  getRequest<string>(
    `${env.IDENTITY_API_HOST}/api/vendors/zendesk/tokens/${id}`,
    env.IDENTITY_API_KEY,
  );

export const getAnnouncements = (language: string): Promise<IAnnouncement[]> =>
  getRequest<IAnnouncement[]>(
    `${env.IDENTITY_API_HOST}/api/announcements/?language=${language}`,
    env.IDENTITY_API_KEY,
  );
