import {
  Button,
  ButtonTypes,
  FormCheckbox,
  FormPhoneFields,
  FormTextField,
  IError,
  IAddressIndexed,
  IPhoneIndexed,
  LoadingOverlay,
  TextFieldTypes,
  countries,
  getGivenNameFromProfile,
  getPrimaryAddressFromProfile,
  getPrimaryPhoneNumberFromProfile,
  getSecondaryPhoneNumberFromProfile,
  getStatesByCountry,
  languages,
  useToast,
  getMobilePhoneNumberFromProfile,
  phoneTypes,
} from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Hooks.
import {
  useProfile,
  useTrackEvent,
  useUpdateProfile,
  useTrackOdpEvent,
} from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

// Styles.
import styles from './ProfileDetailsView.module.scss';

// Validations.
import { getValidationSchema } from './validationSchema';

export const ProfileDetailsView = (): ReactElement => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent('Manage Profile');
  const trackOdpEvent = useTrackOdpEvent();
  const [popToast] = useToast();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [profile] = useProfile();
  const [updateProfile] = useUpdateProfile(profile?.id);
  const hasPearsonAccount = useMemo(
    () => !!profile?.externalIds?.pearsonVue,
    [profile],
  );
  const primaryAddress: IAddressIndexed = useMemo(
    () => getPrimaryAddressFromProfile(profile) ?? ({} as IAddressIndexed),
    [profile],
  );
  const primaryPhoneNumber: IPhoneIndexed = useMemo(
    () => getPrimaryPhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );
  const mobilePhoneNumber: IPhoneIndexed = useMemo(
    () => getMobilePhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );
  const secondaryPhoneNumber: IPhoneIndexed = useMemo(
    () => getSecondaryPhoneNumberFromProfile(profile) ?? ({} as IPhoneIndexed),
    [profile],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      allowConsentToSendSmsTextMessages:
        profile?.allowConsentToSendSmsTextMessages ?? false,
      allowCredentialReleaseToThirdParty:
        profile?.allowCredentialReleaseToThirdParty ?? false,
      alternateEmail: profile?.alternateEmail || '',
      company: profile?.company || '',
      givenName: {
        firstName: profile?.givenName?.firstName || '',
        lastName: profile?.givenName?.lastName || '',
        middleName: profile?.givenName?.middleName || '',
        suffix: profile?.givenName?.suffix || '',
      },
      mobilePhoneNumber: {
        countryCode: mobilePhoneNumber?.countryCode ?? '1',
        index: mobilePhoneNumber?.index,
        isPrimary: mobilePhoneNumber?.isPrimary ?? false,
        phone: mobilePhoneNumber?.phone || '',
        phonetype: mobilePhoneNumber.phoneType || phoneTypes.Cellphone,
      },
      preferences: {
        language: profile?.preferences?.language || '',
      },
      preferredName: {
        firstName: profile?.preferredName?.firstName || '',
        lastName: profile?.preferredName?.lastName || '',
      },
      primaryAddress: {
        address1: primaryAddress?.address1 || '',
        address2: primaryAddress?.address2 || '',
        address3: primaryAddress?.address3 || '',
        city: primaryAddress?.city || '',
        country: primaryAddress?.country || 'USA',
        index: primaryAddress?.index,
        isPrimary: true,
        postalCode: primaryAddress?.postalCode || '',
        state: primaryAddress?.state || '',
      },
      primaryPhoneNumber: {
        countryCode: primaryPhoneNumber?.countryCode ?? '1',
        index: primaryPhoneNumber?.index,
        isPrimary: primaryPhoneNumber?.isPrimary ?? true,
        phone: primaryPhoneNumber?.phone || '',
        phonetype: primaryPhoneNumber.phoneType || phoneTypes.Home,
      },
      secondaryPhoneNumber: {
        countryCode: secondaryPhoneNumber?.countryCode ?? '1',
        index: secondaryPhoneNumber?.index,
        isPrimary: secondaryPhoneNumber?.isPrimary ?? false,
        phone: secondaryPhoneNumber?.phone || '',
        phonetype: secondaryPhoneNumber.phoneType || phoneTypes.Home,
      },
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        trackEvent('Submitted');
        trackOdpEvent('account', 'update', '');
        await updateProfile(values);
        popToast(t('Toast.Success.EditProfile'));
        setIsEditing(false);
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
      setIsSubmitting(false);
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(t, profile),
  });

  useEffect(() => {
    if (!isEditing) {
      formik.resetForm();
    }
  }, [isEditing]);

  const states = useMemo(
    () => getStatesByCountry(formik.values.primaryAddress.country),
    [formik.values.primaryAddress.country],
  );

  const renderSubtitle = () => (
    <>
      <div>{t('EditProfile.Subheading')}</div>
      {profile?.compTIAId && (
        <div>
          <small>
            {t('ProfileMenu.CompTIA')}: {profile?.compTIAId}
          </small>
        </div>
      )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>
      <ContentLayout
        subtitle={renderSubtitle()}
        title={getGivenNameFromProfile(profile)}
      >
        <div className={styles.description}>
          <p>
            <small>
              <strong>{t('PearsonDemographic.NoReimbursement')}</strong>
            </small>
          </p>
          <p>
            <small>
              <strong>{t('PearsonDemographic.NameInEnglish')}</strong>
            </small>
          </p>
          {hasPearsonAccount && (
            <p>
              <small
                dangerouslySetInnerHTML={{ __html: t('EditProfile.LegalName') }}
              />
            </p>
          )}
        </div>
        <form noValidate onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <fieldset>
              <legend>
                {t('EditProfile.PersonalInformation')}
                {isEditing ? (
                  <button
                    className={`${styles.edit} ${styles.cancel}`}
                    onClick={() => setIsEditing(false)}
                    type="button"
                  >
                    {t('Button.Cancel')}
                  </button>
                ) : (
                  <button
                    className={styles.edit}
                    onClick={() => setIsEditing(true)}
                    type="button"
                  >
                    {t('Button.Edit')}
                  </button>
                )}
              </legend>
              <div className={styles.grid}>
                <FormTextField
                  disabled={!isEditing || hasPearsonAccount}
                  label={t('Profile.GivenName.FirstName')}
                  maxLength={30}
                  name="givenName.firstName"
                  required
                />
                <FormTextField
                  disabled={!isEditing || hasPearsonAccount}
                  label={t('Profile.GivenName.MiddleName')}
                  maxLength={30}
                  name="givenName.middleName"
                />
                <FormTextField
                  disabled={!isEditing || hasPearsonAccount}
                  label={t('Profile.GivenName.LastName')}
                  maxLength={50}
                  name="givenName.lastName"
                  required
                />
                <FormTextField
                  disabled={!isEditing || hasPearsonAccount}
                  className={styles.suffix}
                  label={t('Profile.GivenName.Suffix')}
                  name="givenName.suffix"
                  maxLength={10}
                />
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.PreferredName.FirstNameQualified')}
                  maxLength={30}
                  name="preferredName.firstName"
                />
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.PreferredName.LastNameQualified')}
                  maxLength={50}
                  name="preferredName.lastName"
                />
              </div>
            </fieldset>
            <fieldset>
              <legend>{t('EditProfile.ContactInformation')}</legend>
              <div className={styles.grid}>
                <FormPhoneFields
                  disabled={!isEditing}
                  countryCodeClassName={styles.code}
                  countryCodeName="primaryPhoneNumber.countryCode"
                  countryCodeLabel={t('Profile.PhoneNumber.CountryCode')}
                  phoneClassName={styles.phone}
                  phoneName="primaryPhoneNumber.phone"
                  phoneLabel={t('Profile.PhoneNumber.Phone')}
                  required
                  phoneType="primaryPhoneNumber.phonetype"
                />
                <FormPhoneFields
                  disabled={!isEditing}
                  countryCodeClassName={styles.code}
                  countryCodeName="mobilePhoneNumber.countryCode"
                  countryCodeLabel={t('Profile.MobilePhoneNumber.CountryCode')}
                  phoneClassName={styles.phone}
                  phoneName="mobilePhoneNumber.phone"
                  phoneLabel={t('Profile.MobilePhoneNumber.Phone')}
                  phoneType="mobilePhoneNumber.phonetype"
                  required={formik.values.allowConsentToSendSmsTextMessages}
                />
                <FormCheckbox
                  disabled={!isEditing}
                  label={t('Profile.AllowConsentToSendSmsTextMessages')}
                  name="allowConsentToSendSmsTextMessages"
                />
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.Address.Country')}
                  name="primaryAddress.country"
                  onChange={() => {
                    formik.setFieldValue('primaryAddress.state', '');
                  }}
                  required
                  select
                >
                  {countries?.map((country) => (
                    <option
                      key={country.abbreviation}
                      value={country.abbreviation}
                    >
                      {country.name}
                    </option>
                  ))}
                </FormTextField>
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.Address.Address1')}
                  maxLength={40}
                  name="primaryAddress.address1"
                  required
                />
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.Address.Address2')}
                  maxLength={40}
                  name="primaryAddress.address2"
                />
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.Address.Address3')}
                  maxLength={40}
                  name="primaryAddress.address3"
                />
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.Address.City')}
                  maxLength={32}
                  name="primaryAddress.city"
                  required
                />
                {states.length > 0 && (
                  <FormTextField
                    disabled={!isEditing}
                    label={t('Profile.Address.State')}
                    name="primaryAddress.state"
                    required={['USA', 'CAN'].includes(
                      formik.values.primaryAddress.country,
                    )}
                    select
                  >
                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </FormTextField>
                )}
                <FormTextField
                  disabled={!isEditing}
                  className={styles.zip}
                  label={t('Profile.Address.PostalCode')}
                  maxLength={16}
                  name="primaryAddress.postalCode"
                  required={['USA', 'CAN'].includes(
                    formik.values.primaryAddress.country,
                  )}
                />
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.Company')}
                  maxLength={50}
                  name="company"
                />
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.Preferences.Language')}
                  name="preferences.language"
                  select
                >
                  {languages.map((language) => (
                    <option
                      key={language.abbreviation}
                      value={language.abbreviation}
                    >
                      {language.name}
                    </option>
                  ))}
                </FormTextField>
              </div>
            </fieldset>
            <fieldset>
              <legend>{t('EditProfile.AlternateContactInformation')}</legend>
              <div className={styles.grid}>
                <p className={styles.alternate}>
                  <small>{t('EditProfile.AlternateContactSubheading')}</small>
                </p>
                <FormTextField
                  disabled={!isEditing}
                  label={t('Profile.AlternateEmail')}
                  maxLength={255}
                  name="alternateEmail"
                  type={TextFieldTypes.Email}
                />
                <FormPhoneFields
                  disabled={!isEditing}
                  countryCodeClassName={styles.code}
                  countryCodeName="secondaryPhoneNumber.countryCode"
                  countryCodeLabel={t(
                    'Profile.AlternatePhoneNumber.CountryCode',
                  )}
                  phoneClassName={styles.phone}
                  phoneName="secondaryPhoneNumber.phone"
                  phoneLabel={t('Profile.AlternatePhoneNumber.Phone')}
                  phoneType="secondaryPhoneNumber.phonetype"
                />
              </div>
            </fieldset>
            <fieldset>
              <div className={styles.grid}>
                <FormCheckbox
                  disabled={!isEditing}
                  label={t('Profile.AllowCredentialReleaseToThirdParty')}
                  name="allowCredentialReleaseToThirdParty"
                />
              </div>
            </fieldset>
            <Button
              className={styles.button}
              disabled={!isEditing}
              type={ButtonTypes.Submit}
            >
              {t('EditProfile.Cta')}
            </Button>
          </FormikProvider>
        </form>
        <LoadingOverlay
          isOpen={isSubmitting}
          text={t('Loading.UpdatingProfile')}
        />
      </ContentLayout>
    </>
  );
};
