import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Translations.
import { translation as enTranslation } from 'locales/en/translation';
import { translation as jaTranslation } from 'locales/ja/translation';

i18n.use(LanguageDetector).init({
  detection: {
    caches: ['sessionStorage'],
    lookupQuerystring: 'ui_locales',
    order: ['querystring', 'sessionStorage'],
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react is already safe from xss
  },
  resources: {
    en: { translation: enTranslation },
    ja: { translation: jaTranslation },
  },
});

export { i18n };
