import classNames from 'classnames';
import { ReactElement } from 'react';

// Styles.
import styles from './Announcement.module.scss';

interface IAnnouncementProps {
  heading?: string;
  message: string;
}

export const Announcement = (props: IAnnouncementProps): ReactElement => {
  const { heading, message } = props;

  return (
    <div className={styles.announcement}>
      {heading && <div className={styles.heading}>{heading}</div>}
      <div
        className={classNames(styles.message)}
        dangerouslySetInnerHTML={{ __html: message.replaceAll('\n', '<br/>') }}
      />
    </div>
  );
};
