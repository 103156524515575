import { yup } from '@comptia-sso/core';
import { TFunction } from 'react-i18next';

export const getValidationSchema = (t: TFunction): yup.SchemaOf<unknown> =>
  yup.object().shape({
    allowConsentToGatherBirthInformation: yup
      .bool()
      .oneOf([true], t('Validation.Required'))
      .default(false),
    allowConsentToSendSmsTextMessages: yup.boolean(),
    birthDay: yup
      .number()
      .nullable()
      .required(t('Validation.Required'))
      .test('validBirthDay', t('Validation.ValidBirthDay'), function (value) {
        const days = new Date(
          this.parent.birthYear,
          this.parent.birthMonth,
          0,
        ).getDate();
        value = value ?? 0;
        if (value > days) {
          return false;
        } else {
          return true;
        }
      }),
    birthMonth: yup.string().nullable().required(t('Validation.Required')),
    birthYear: yup.number().nullable().required(t('Validation.Required')),
    isOlderThanEighteenYears: yup
      .boolean()
      .oneOf([true], t('Validation.Required'))
      .default(false),
    mobilePhoneNumber: yup.object().shape({
      countryCode: yup
        .string()
        .required(t('Validation.Required'))
        .isValidPhoneNumberCountryCode(t),
      phone: yup
        .string()
        .required(t('Validation.Required'))
        .isValidPhoneNumber(t),
    }),
    primaryAddress: yup.object().shape({
      address1: yup
        .string()
        .required(t('Validation.Required'))
        .isValidAddressLine1(t),
      address2: yup.string().isValidAddressLine2(t),
      address3: yup.string().isValidAddressLine3(t),
      city: yup.string().required(t('Validation.Required')).isValidCity(t),
      country: yup
        .string()
        .required(t('Validation.Required'))
        .isValidCountry(t),
      postalCode: yup
        .string()
        .isValidPostalCode(t)
        .when('country', {
          is: (value: string) => ['CAN', 'USA'].includes(value),
          then: yup.string().required(t('Validation.Required')),
        }),
      state: yup
        .string()
        .isValidState(t)
        .when('country', {
          is: (value: string) => ['CAN', 'USA'].includes(value),
          then: yup.string().required(t('Validation.Required')),
        }),
    }),
    primaryPhoneNumber: yup.object().shape({
      countryCode: yup
        .string()
        .required(t('Validation.Required'))
        .isValidPhoneNumberCountryCode(t),
      phone: yup
        .string()
        .required(t('Validation.Required'))
        .isValidPhoneNumber(t),
    }),
  });
