import { useCallback } from 'react';

// Services.
import { getSkillableSSOUrl } from 'services';

export const useRedirectToSkillable = (id = '', target = ''): VoidFunction => {
  return useCallback(async () => {
    const response = await getSkillableSSOUrl(id);
    window.open(response?.url, target || '_blank');
  }, [id]);
};
