import {
  Button,
  IError,
  List,
  ListItem,
  LoadingOverlay,
  Vendors,
  useToast,
} from '@comptia-sso/core';
import { MouseEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// Config.
import { env } from 'config';

// Hooks.
import {
  useProfile,
  useRedirectToVendor,
  useTrackEvent,
  useTrackOdpEvent,
} from 'hooks';

// Styles.
import styles from '../../views/HomeView/HomeView.module.scss';

export const CertMetricsAccount = (): ReactElement => {
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [profile] = useProfile();
  const [redirectToVendor, { isLoading: isRedirecting }] = useRedirectToVendor(
    profile?.id,
  );
  const trackEvent = useTrackEvent('Manage Your Certifications');
  const trackOdpEvent = useTrackOdpEvent();

  const handleEvent = (e: MouseEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    trackEvent('Clicked', { label: element.innerText });
    trackOdpEvent('navigation', 'Launchpad Button', 'Manage Certifications');
  };

  return (
    <>
      <div id="certMetricsAccount" className={styles.card}>
        <div className={styles.content}>
          <h2>{t('CertMetricsAccount.Title')}</h2>
          <p>{t('CertMetricsAccount.Content')}</p>
          {env.FLAG_CERTMETRICS_ENABLED && profile?.compTIAId ? (
            <>
              <List>
                <ListItem>
                  <a
                    onClick={async (e) => {
                      e.preventDefault();
                      handleEvent(e);

                      try {
                        await redirectToVendor(Vendors.CertMetrics, {
                          returnUrl: '/candidate/cert_summary.aspx',
                        });
                      } catch (error) {
                        popToast(
                          (error as IError)?.message ||
                            t('Toast.Error.Default'),
                        );
                      }
                    }}
                    href="#"
                  >
                    {t('CertMetricsAccount.List-1')}
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    onClick={async (e) => {
                      e.preventDefault();
                      handleEvent(e);

                      try {
                        await redirectToVendor(Vendors.CertMetrics, {
                          returnUrl: '/candidate/ce_summary.aspx',
                        });
                      } catch (error) {
                        popToast(
                          (error as IError)?.message ||
                            t('Toast.Error.Default'),
                        );
                      }
                    }}
                    href="#"
                  >
                    {t('CertMetricsAccount.List-3')}
                  </a>
                </ListItem>
                <ListItem>
                  <a
                    onClick={async (e) => {
                      e.preventDefault();
                      handleEvent(e);

                      try {
                        await redirectToVendor(Vendors.CertMetrics, {
                          returnUrl: '/candidate/cert_summary.aspx',
                        });
                      } catch (error) {
                        popToast(
                          (error as IError)?.message ||
                            t('Toast.Error.Default'),
                        );
                      }
                    }}
                    href="#"
                  >
                    {t('CertMetricsAccount.List-4')}
                  </a>
                </ListItem>
              </List>
              <Button
                className={styles.button}
                onClick={async (e) => {
                  handleEvent(e);

                  try {
                    await redirectToVendor(Vendors.CertMetrics);
                  } catch (error) {
                    popToast(
                      (error as IError)?.message || t('Toast.Error.Default'),
                    );
                  }
                }}
              >
                {t('CertMetricsAccount.Button')}
              </Button>
            </>
          ) : (
            <>
              <List>
                <ListItem>{t('CertMetricsAccount.List-1')}</ListItem>
                <ListItem>{t('CertMetricsAccount.List-3')}</ListItem>
                <ListItem>{t('CertMetricsAccount.List-4')}</ListItem>
              </List>
              <Button
                className={styles.button}
                link
                onClick={(e) => {
                  handleEvent(e);
                }}
                to="https://www.comptia.org/certifications"
              >
                {t('CertMetricsAccount.Button-2')}
              </Button>
            </>
          )}
        </div>
      </div>
      <LoadingOverlay isOpen={isRedirecting} text={t('Loading.Default')} />
    </>
  );
};
