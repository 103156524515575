import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Config.
import { env } from 'config';

// Services.
import { getHasPendingFulfillments } from 'services';

type UsePendingFulfillments = [boolean, IQueryHookOptions];

export const useHasPendingFulfillments = (id = ''): UsePendingFulfillments => {
  const {
    data = false,
    error,
    isError,
    isLoading,
  } = useQuery<boolean, IError>(
    'hasPendingFulfillments',
    () => getHasPendingFulfillments(id),
    {
      enabled: env.FLAG_CERTMETRICS_ENABLED && !!id,
    },
  );
  return [data, { error, isError, isLoading }];
};
