import classNames from 'classnames';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components.
import {
  AccessYourTraining,
  CertMetricsAccount,
  ExamRegistration,
  PearsonVueDashboard,
  NeedHelp,
  RedeemAccessKeys,
  Announcement,
} from 'components';

// Hooks.
import { useProfile, useAnnouncements } from 'hooks';

// Images.
import heroImage from 'images/hero-launchpad.jpg';

// Styles.
import styles from './HomeView.module.scss';

export const HomeView = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const [profile] = useProfile();
  const [announcements] = useAnnouncements(i18n.language);

  const renderAnnouncements = () => {
    return (
      <section
        id="announcements"
        className={classNames(styles.section, styles.sectionAnnouncements)}
      >
        <div className={styles.container}>
          {announcements?.map((announcement) => (
            <Announcement
              heading={announcement.heading}
              message={announcement.message}
              key={announcement.id}
            />
          ))}
        </div>
      </section>
    );
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className={styles.container}>
        {announcements?.length > 0 ? renderAnnouncements() : <p />}
        <section
          id="examRegistration"
          className={classNames(styles.section, styles.sectionHero)}
        >
          <div className={styles.container}>
            <div className={styles.welcome}>
              <div>
                {t('WelcomeHeading')} {profile?.givenName?.firstName}
              </div>
            </div>
            <div className={styles.hero}>
              <img src={heroImage} alt="" />
            </div>
            <ExamRegistration profile={profile} />
          </div>
        </section>

        {/* Section / PearsonVueDashboard + CertMetrics + LightTeal */}
        <section
          id="pearsonVueDashboard"
          className={classNames(
            styles.section,
            styles.sectionCards,
            styles.sectionLightTeal,
            styles.sectionNoPaddingBottom,
          )}
        >
          <div className={styles.container}>
            <PearsonVueDashboard />
            <CertMetricsAccount />
          </div>
        </section>
        <div
          className={classNames(
            styles.section,
            styles.sectionLightTeal,
            styles.sectionNoPaddingTop,
          )}
        />

        {/* Section / Access Your Training */}
        <section
          id="accessYourTraining"
          className={classNames(styles.section, styles.sectionLgPaddingTop)}
        >
          <AccessYourTraining />
        </section>

        {/* Section / RedeemAccessKeys + AssociateCertMetrics + LightBlue */}
        <section
          id="redeemAccessKeys"
          className={classNames(
            styles.section,
            styles.sectionCards,
            styles.sectionLightBlue,
          )}
        >
          <div className={styles.container}>
            <RedeemAccessKeys />
            <NeedHelp />
          </div>
        </section>
      </div>
    </>
  );
};
