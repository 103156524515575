import { TFunction } from 'react-i18next';

export enum PearsonErrorCodes {
  Default = 'ERROR',
  CandidateNotFound = 'ERROR_CANDIDATE_NOT_FOUND',
  ClientNotSupported = 'ERROR_CLIENT_NOT_SUPPORTED',
  InvalidPayload = 'ERROR_INVALID_PAYLOAD',
  MissingRequiredParameters = 'ERROR_MISSING_REQUIRED_PARAMETERS',
  RegistrationNotFound = 'ERROR_REGISTRATION_NOT_FOUND',
  SessionTimeout = 'ERROR_SESSION_TIMEOUT',
  Temporary = 'ERROR_TEMPORARY',
  VueWideBlock = 'ERROR_VUE_WIDE_BLOCK',
}

export const getPearsonError = (
  errorCode: string,
  t: TFunction,
): string | undefined => {
  if (!errorCode) return;

  const pearsonErrorMap: Record<string, string> = {
    [PearsonErrorCodes.Default]: t('PearsonError.Default'),
    [PearsonErrorCodes.CandidateNotFound]: t('PearsonError.CandidateNotFound'),
    [PearsonErrorCodes.ClientNotSupported]: t(
      'PearsonError.ClientNotSupported',
    ),
    [PearsonErrorCodes.InvalidPayload]: t('PearsonError.InvalidPayload'),
    [PearsonErrorCodes.MissingRequiredParameters]: t(
      'PearsonError.MissingRequiredParameters',
    ),
    [PearsonErrorCodes.RegistrationNotFound]: t(
      'PearsonError.RegistrationNotFound',
    ),
    [PearsonErrorCodes.SessionTimeout]: t('PearsonError.SessionTimeout'),
    [PearsonErrorCodes.Temporary]: t('PearsonError.Temporary'),
    [PearsonErrorCodes.VueWideBlock]: t('PearsonError.VueWideBlock'),
  };

  return pearsonErrorMap[errorCode];
};
