import { useCallback } from 'react';

// Config.
import { env } from 'config';

export const useRedirectToGutenberg = (): ((target?: string) => void) => {
  return useCallback((target?: string) => {
    window.open(env.GUTENBERG_SSO_HOST, target || '_blank');
  }, []);
};
