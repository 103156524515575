import {
  IError,
  IProfile,
  IQueryHookOptions,
  IUpdateProfileRequest,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { updateProfile } from 'services';

type UpdateProfileFunction = UseMutateAsyncFunction<
  IProfile,
  IError,
  IUpdateProfileRequest
>;

type UseUpdateProfile = [UpdateProfileFunction, IQueryHookOptions];

export const useUpdateProfile = (id = ''): UseUpdateProfile => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IProfile,
    IError,
    IUpdateProfileRequest
  >((values: IUpdateProfileRequest) => updateProfile(id, values), {
    onSuccess: (data) => {
      queryClient.setQueryData('profile', data);
    },
  });

  return [mutateAsync, { error, isError, isLoading }];
};
