import { IError, LoadingOverlay, useToast, Vendors } from '@comptia-sso/core';
import { useProfile, useRedirectToVendor } from 'hooks';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const HelpView = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [popToast] = useToast();
  const [profile] = useProfile();
  const [redirectToVendor, { isLoading: isRedirecting }] = useRedirectToVendor(
    profile?.id,
  );

  useEffect(() => {
    (async () => {
      try {
        await redirectToVendor(Vendors.Zendesk);
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
        navigate('/');
      }
    })();
  }, [profile]);

  return <LoadingOverlay isOpen={isRedirecting} text={t('Loading.Default')} />;
};
