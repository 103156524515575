import { Configuration, RedirectRequest } from '@azure/msal-browser';
import { i18n } from 'i18n';

// Config.
import { env } from './env';

export const msalConfig: Configuration = {
  auth: {
    authority: `https://${env.B2C_DOMAIN}/${env.B2C_TENANT}/${env.B2C_POLICY}`,
    clientId: env.B2C_CLIENT_ID,
    knownAuthorities: [env.B2C_DOMAIN],
    redirectUri: window.location.origin,
  },
};

export const msalRequest: RedirectRequest = {
  extraQueryParameters: { ui_locales: i18n.language },
  scopes: ['openid', env.B2C_CLIENT_ID],
};
