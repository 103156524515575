import { ReactElement, useEffect } from 'react';
import { useProfile } from 'hooks';

export const WOOTRIC_ACCOUNT_TOKEN = 'NPS-88f9d834'; // This is your unique account token.

declare const window: Window &
  typeof globalThis & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wootric: any;
  };

export const WootricSurvey = (): ReactElement => {
  const [profile] = useProfile();

  useEffect(() => {
    (async () => {
      if (profile) {
        const setupScript = document.createElement('script');
        setupScript.type = 'text/javascript';
        setupScript.id = 'wootric-settings';
        setupScript.async = true;
        setupScript.innerHTML = `
          window.wootricSettings = {
            email: '${profile?.email};',
            created_at: ${Math.floor(Date.now() / 1000)},
            account_token: "${WOOTRIC_ACCOUNT_TOKEN}"
          };
        `;

        document.body.appendChild(setupScript);

        // Beacon
        const beacon = document.createElement('script');
        beacon.type = 'text/javascript';
        beacon.id = 'wootric-beacon';
        beacon.async = true;

        beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
        beacon.onload = function () {
          window.wootric('run');
        };
        if (document.getElementById('wootric-beacon') == null) {
          document.body.appendChild(beacon);
        }
      }
    })();
  }, [profile]);

  return <div />;
};

export default WootricSurvey;
