import {
  IError,
  IProfile,
  IQueryHookOptions,
  IUpdateCertMasterPracticeProfile,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { updateCertMasterPracticeProfile } from 'services';

type updateCertMasterPracticeProfileFunction = UseMutateAsyncFunction<
  IProfile,
  IError,
  IUpdateCertMasterPracticeProfile
>;

type UseUpdateCertMasterPracticeProfile = [
  updateCertMasterPracticeProfileFunction,
  IQueryHookOptions,
];

export const useUpdateCertMasterPracticeProfile = (
  profileId = '',
): UseUpdateCertMasterPracticeProfile => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IProfile,
    IError,
    IUpdateCertMasterPracticeProfile
  >(
    (values: IUpdateCertMasterPracticeProfile) =>
      updateCertMasterPracticeProfile(profileId, values),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('profile', data);
      },
    },
  );

  return [mutateAsync, { error, isError, isLoading }];
};
