import {
  Button,
  ButtonVariants,
  ProfileInitials,
  getGivenNameFromProfile,
} from '@comptia-sso/core';
import { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';

// Config.
import { env } from 'config';

// Hooks.
import {
  useAuthLogout,
  useProfile,
  useTrackEvent,
  useTrackOdpEvent,
} from 'hooks';

// Icons.
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SecurityIcon from '@material-ui/icons/Security';

// Styles.
import styles from './ProfileMenu.module.scss';

export const ProfileMenu = (): ReactElement => {
  const { t } = useTranslation();
  const [profile] = useProfile();
  const logout = useAuthLogout();
  const location = useLocation();
  const trackEvent = useTrackEvent('Profile Menu');
  const trackOdpEvent = useTrackOdpEvent();

  const [isActive, setIsActive] = useState(false);

  const handleOpen = () => {
    if (!isActive) {
      setIsActive(true);
      trackEvent('Opened');
    }
  };

  const handleClose = () => {
    if (isActive) {
      setIsActive(false);
      trackEvent('Closed');
    }
  };

  const ref = useOnclickOutside(handleClose);

  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const link = e.target as HTMLAnchorElement;
    trackEvent('Clicked', { label: link.innerText });
  };

  const handleLogoutClick = (e: MouseEvent<HTMLButtonElement>) => {
    const button = e.target as HTMLButtonElement;
    trackOdpEvent('account', 'logout', '');
    trackEvent('Clicked', { label: button.innerText });
    logout();
  };

  useEffect(() => {
    setIsActive(false);
  }, [location]);

  return (
    <div className={styles.profileMenu}>
      <ProfileInitials
        profile={profile}
        className={styles.profileInitialsSmall}
        onClick={handleOpen}
      />
      {isActive && (
        <div ref={ref} className={styles.profileMenuDropdown}>
          <button
            aria-label="Close Profile Menu"
            className={styles.close}
            onClick={handleClose}
            type="button"
          >
            <HighlightOffIcon />
          </button>
          <div className={styles.profileMenuDetails}>
            <ProfileInitials
              profile={profile}
              className={styles.profileInitials}
            />
            <div className={styles.profileMenuName}>
              {getGivenNameFromProfile(profile)}
            </div>
            <div className={styles.profileMenuEmailAddress}>
              {profile?.email}
            </div>
            {profile?.compTIAId && (
              <div className={styles.profileMenuCompTIAID}>
                {t('ProfileMenu.CompTIA')}: {profile?.compTIAId}
              </div>
            )}
          </div>

          <ul className={styles.menuList}>
            <li>
              <Link onClick={handleLinkClick} to="/profile">
                <PersonOutlineIcon /> {t('ProfileMenu.Nav-1')}
                <ArrowForwardIosIcon className={styles.arrowForward} />
              </Link>
            </li>
            <li>
              <Link onClick={handleLinkClick} to="/account">
                <SecurityIcon /> {t('ProfileMenu.Nav-2')}
                <ArrowForwardIosIcon className={styles.arrowForward} />
              </Link>
            </li>
            {env.FLAG_LEARNING_SYSTEMS_ENABLED && (
              <li>
                <Link onClick={handleLinkClick} to="/training-products">
                  <FormatListNumberedIcon /> {t('ProfileMenu.Nav-3')}
                  <ArrowForwardIosIcon className={styles.arrowForward} />
                </Link>
              </li>
            )}
          </ul>
          <Button
            className={styles.button}
            onClick={handleLogoutClick}
            variant={ButtonVariants.Secondary}
          >
            {t('ProfileMenu.Button')}
          </Button>
          <footer className={styles.footer}>
            <a
              href="https://www.comptia.org/legal"
              onClick={handleLinkClick}
              rel="noreferrer"
              target="_blank"
            >
              {t('ProfileMenu.Link-1')}
            </a>
          </footer>
        </div>
      )}
    </div>
  );
};
