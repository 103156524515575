import { ErrorModal } from '@comptia-sso/core';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getPearsonError } from './pearsonErrorMap';

export interface IPearsonErrorModal {
  errorCode?: string;
  isDisabled?: boolean;
}

export const PearsonErrorModal = (props: IPearsonErrorModal): ReactElement => {
  const { errorCode, isDisabled } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const error = useMemo(
    () => getPearsonError(errorCode as string, t),
    [errorCode, t],
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    navigate('/');
    setIsOpen(false);
  }, [navigate]);

  useEffect(() => {
    if (error) {
      setIsOpen(true);
    }
  }, [error]);

  return (
    <ErrorModal
      buttonText={t('PearsonError.Button')}
      error={error as string}
      onClose={handleClose}
      open={!isDisabled && isOpen}
    />
  );
};
