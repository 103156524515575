import { yup } from '@comptia-sso/core';
import { TFunction } from 'react-i18next';

export const getValidationSchema = (t: TFunction): yup.SchemaOf<unknown> =>
  yup.object().shape({
    address1: yup
      .string()
      .required(t('Validation.Required'))
      .isValidAddressLine1(t),
    address2: yup.string().isValidAddressLine2(t),
    address3: yup.string().isValidAddressLine3(t),
    city: yup.string().required(t('Validation.Required')).isValidCity(t),
    country: yup.string().required(t('Validation.Required')).isValidCountry(t),
    postalCode: yup
      .string()
      .isValidPostalCode(t)
      .when('country', {
        is: (value: string) => ['CAN', 'USA'].includes(value),
        then: yup.string().required(t('Validation.Required')),
      }),
    state: yup
      .string()
      .isValidState(t)
      .when('country', {
        is: (value: string) => ['CAN', 'USA'].includes(value),
        then: yup.string().required(t('Validation.Required')),
      }),
  });
