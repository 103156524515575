import {
  IError,
  LoadingOverlay,
  Vendors,
  useQueryParams,
  useToast,
} from '@comptia-sso/core';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

// Hooks.
import { useProfile, useRedirectToVendor, useTrackOdpEvent } from 'hooks';
import { env } from 'config';
import { OptimizelyDataPlatform } from 'components';

export const LoginView = (): ReactElement => {
  const navigate = useNavigate();
  const { redirectUrl, redirectVendor, target } = useQueryParams();
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [profile, { isLoading: isLoadingProfile }] = useProfile();
  const [redirectToVendor] = useRedirectToVendor(profile?.id, target);
  const trackOdpEvent = useTrackOdpEvent();

  useEffect(() => {
    (async () => {
      trackOdpEvent('account', 'login', '');
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else if (redirectVendor) {
        if (!isLoadingProfile && profile) {
          try {
            if (
              redirectVendor.toLowerCase() ===
                Vendors.CertMasterPractice.toLowerCase() &&
              profile?.externalIds?.certMasterPractice
            ) {
              await redirectToVendor(Vendors.CertMasterPractice);
            } else if (
              redirectVendor.toLowerCase() ===
                Vendors.CertMetrics.toLowerCase() &&
              profile?.compTIAId
            ) {
              await redirectToVendor(Vendors.CertMetrics);
            } else if (
              redirectVendor.toLowerCase() ===
                Vendors.Skillable.toLowerCase() &&
              profile?.externalIds?.skillable
            ) {
              await redirectToVendor(Vendors.Skillable);
            } else if (
              redirectVendor.toLowerCase() ===
                Vendors.PearsonVue.toLowerCase() &&
              profile?.externalIds?.pearsonVue
            ) {
              await redirectToVendor(Vendors.PearsonVue);
            } else if (
              redirectVendor.toLowerCase() === Vendors.Zendesk.toLowerCase() &&
              profile?.id
            ) {
              await redirectToVendor(Vendors.Zendesk);
            } else if (
              redirectVendor.toLowerCase() === Vendors.Canvas.toLowerCase() &&
              profile?.externalIds.canvas
            ) {
              await redirectToVendor(Vendors.Canvas);
            } else if (
              redirectVendor.toLowerCase() === Vendors.Populi.toLowerCase() &&
              profile?.externalIds.populi
            ) {
              await redirectToVendor(Vendors.Populi);
            } else if (
              redirectVendor.toLowerCase() ===
                Vendors.BenchPrep.toLowerCase() &&
              profile?.externalIds?.benchPrep
            ) {
              await redirectToVendor(Vendors.BenchPrep);
            } else if (
              redirectVendor.toLowerCase() === Vendors.Gutenberg.toLowerCase()
            ) {
              await redirectToVendor(Vendors.Gutenberg);
            } else {
              navigate('/');
            }
          } catch (error) {
            popToast((error as IError)?.message || t('Toast.Error.Default'));
            navigate('/');
          }
        }
      } else {
        navigate('/');
      }
    })();
  }, [isLoadingProfile, profile, redirectToVendor, redirectUrl]);

  return (
    <>
      {env.FLAG_ODP_ENABLED && <OptimizelyDataPlatform />}
      <LoadingOverlay isOpen text={t('Loading.Default')} />
    </>
  );
};
