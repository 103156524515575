import { useCallback } from 'react';
import ReactGA from 'react-ga4';

export const useTrackModalview = (name: string): VoidFunction => {
  return useCallback(() => {
    // Wait 500ms to allow react to update page attributes.
    setTimeout(() => {
      ReactGA.send({
        hitType: 'pageview',
        page: `/modal/${name}`,
        title: '',
      });
    }, 500);
  }, []);
};
